import {
    getIntegrationsStatus,
    getServiceNowGroups,
    getPrometheusSettings,
    updatePrometheusSettings,
    getSplunkSettings,
    updateSplunkSettings,
    getSumoLogicSettings,
    updateSumoLogicSettings,
    getNewrelicSettings,
    updateNewrelicSettings,
    getDatadogSettings,
    updateDatadogSettings,
    getPingdomSettings,
    updatePingdomSettings,
    getGoogleDriveSettings,
    createGoogleDriveSettings,
    updateGoogleDriveSettings,
    getServiceNowUsers,
    getServiceNowProblemTaskTypes
} from 'src/actions/integration/integration-actions';
import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    getIntegrationsStatusLoadStatus: string;
    integrationsStatus: IIntegration.IIntegrationStatus;
    serviceNow: {
        groups: { id: string; name: string; users: { id: string; name: string }[] }[];
        users: { id: string; name: string }[];
        taskTypes: { label: string; value: string }[];
    };
    serviceNowGroupsLoadStatus: string;
    serviceNowUsersLoadStatus: string;
    serviceNowProblemTaskTypesLoadStatus: string;
    prometheusSettings: Settings.IPrometheusSettings;
    prometheusLoadStatus: string;
    splunkSettings: Settings.ISplunkSettings;
    splunkLoadStatus: string;
    sumoLogicSettings: Settings.ISumoLogicSettings;
    sumoLogicLoadStatus: string;
    newrelicSettings: Settings.INewrelicSettings;
    newrelicLoadStatus: string;
    datadogSettings: Settings.IDatadogSettings;
    datadogLoadStatus: string;
    pingdomSettings: Settings.IPingdomSettings;
    pingdomLoadStatus: string;
    googleDriveSettings?: Settings.IGoogleDriveSettings;
    googleDriveLoadStatus: string;
}

export const defaultState: IDefaultState = {
    getIntegrationsStatusLoadStatus: LoadStatus.EMPTY,
    integrationsStatus: {},
    serviceNow: {
        groups: [],
        users: [],
        taskTypes: []
    },
    serviceNowGroupsLoadStatus: LoadStatus.EMPTY,
    serviceNowUsersLoadStatus: LoadStatus.EMPTY,
    serviceNowProblemTaskTypesLoadStatus: LoadStatus.EMPTY,
    prometheusSettings: {
        enabled: false,
        mode: 'direct',
        apiUrl: '',
        username: '',
        password: '',
        lambdaUrl: '',
        lambdaToken: '',
        gcp: {}
    },
    prometheusLoadStatus: LoadStatus.EMPTY,
    splunkSettings: {
        enabled: false,
        mode: 'aws',
        url: '',
        accessToken: ''
    },
    splunkLoadStatus: LoadStatus.EMPTY,
    sumoLogicSettings: {
        enabled: false,
        apiUrl: '',
        apiKey: ''
    },
    sumoLogicLoadStatus: LoadStatus.EMPTY,
    newrelicSettings: {
        enabled: false,
        key: ''
    },
    newrelicLoadStatus: LoadStatus.EMPTY,
    datadogSettings: {
        enabled: false,
        apiKey: '',
        applicationKey: ''
    },
    datadogLoadStatus: LoadStatus.EMPTY,
    pingdomSettings: {
        enabled: false,
        apiToken: ''
    },
    pingdomLoadStatus: LoadStatus.EMPTY,
    googleDriveLoadStatus: LoadStatus.EMPTY
};

export default function (state: IDefaultState = defaultState, action: Common.IReducerAction) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getIntegrationsStatus.request.getType():
                draft.getIntegrationsStatusLoadStatus = LoadStatus.REQUEST;
                return;
            case getIntegrationsStatus.ok.getType():
                draft.integrationsStatus = action.payload.response.integrations;
                draft.getIntegrationsStatusLoadStatus = LoadStatus.OK;
                return;
            case getIntegrationsStatus.error.getType():
                draft.getIntegrationsStatusLoadStatus = LoadStatus.ERROR;
                return;
            case getServiceNowGroups.request.getType():
                draft.serviceNowGroupsLoadStatus = LoadStatus.REQUEST;
                return;
            case getServiceNowGroups.ok.getType():
                draft.serviceNowGroupsLoadStatus = LoadStatus.OK;
                draft.serviceNow.groups = action.payload.response.data.groups;
                return;
            case getServiceNowGroups.error.getType():
                draft.serviceNowGroupsLoadStatus = LoadStatus.ERROR;
                draft.serviceNow.groups = [];
                return;
            case getServiceNowUsers.request.getType():
                draft.serviceNowUsersLoadStatus = LoadStatus.REQUEST;
                return;
            case getServiceNowUsers.ok.getType():
                draft.serviceNowUsersLoadStatus = LoadStatus.OK;
                draft.serviceNow.users = action.payload.response.data.users;
                return;
            case getServiceNowUsers.error.getType():
                draft.serviceNow.users = [];
                draft.serviceNowUsersLoadStatus = LoadStatus.ERROR;
                return;
            case getServiceNowProblemTaskTypes.request.getType():
                draft.serviceNowProblemTaskTypesLoadStatus = LoadStatus.REQUEST;
                return;
            case getServiceNowProblemTaskTypes.ok.getType():
                draft.serviceNow.taskTypes = action.payload.response.data.types;
                draft.serviceNowProblemTaskTypesLoadStatus = LoadStatus.OK;
                return;
            case getServiceNowProblemTaskTypes.error.getType():
                draft.serviceNow.taskTypes = [];
                draft.serviceNowProblemTaskTypesLoadStatus = LoadStatus.ERROR;
                return;
            case getPrometheusSettings.request.getType():
            case updatePrometheusSettings.request.getType():
                draft.prometheusLoadStatus = LoadStatus.REQUEST;
                return;
            case getPrometheusSettings.ok.getType():
            case updatePrometheusSettings.ok.getType():
                draft.prometheusLoadStatus = LoadStatus.OK;
                const prometheusPayload = action.payload.response.response;
                delete prometheusPayload.tenantId;
                draft.prometheusSettings = prometheusPayload;
                return;
            case getPrometheusSettings.error.getType():
            case updatePrometheusSettings.error.getType():
                draft.prometheusLoadStatus = LoadStatus.ERROR;
                return;

            case getSplunkSettings.request.getType():
            case updateSplunkSettings.request.getType():
                draft.splunkLoadStatus = LoadStatus.REQUEST;
                return;
            case getSplunkSettings.ok.getType():
            case updateSplunkSettings.ok.getType():
                draft.splunkLoadStatus = LoadStatus.OK;
                const splunkPayload = action.payload.response.response;
                delete splunkPayload.tenantId;
                draft.splunkSettings = splunkPayload;
                return;
            case getSplunkSettings.error.getType():
            case updateSplunkSettings.error.getType():
                draft.splunkLoadStatus = LoadStatus.ERROR;

            case getSumoLogicSettings.request.getType():
            case updateSumoLogicSettings.request.getType():
                draft.sumoLogicLoadStatus = LoadStatus.REQUEST;
                return;
            case getSumoLogicSettings.ok.getType():
            case updateSumoLogicSettings.ok.getType():
                draft.sumoLogicLoadStatus = LoadStatus.OK;
                const sumoLogicPayload = action.payload.response.response;
                delete sumoLogicPayload.tenantId;
                draft.sumoLogicSettings = sumoLogicPayload;
                return;
            case getSumoLogicSettings.error.getType():
            case updateSumoLogicSettings.error.getType():
                draft.sumoLogicLoadStatus = LoadStatus.ERROR;
                return;

            case getNewrelicSettings.request.getType():
            case updateNewrelicSettings.request.getType():
                draft.newrelicLoadStatus = LoadStatus.REQUEST;
                return;
            case getNewrelicSettings.ok.getType():
            case updateNewrelicSettings.ok.getType():
                draft.newrelicLoadStatus = LoadStatus.OK;
                const newrelicPayload = action.payload.response.response;
                delete newrelicPayload.tenantId;
                draft.newrelicSettings = newrelicPayload;
                return;
            case getNewrelicSettings.error.getType():
            case updateNewrelicSettings.error.getType():
                draft.newrelicLoadStatus = LoadStatus.ERROR;
                return;
            case getDatadogSettings.request.getType():
            case updateDatadogSettings.request.getType():
                draft.datadogLoadStatus = LoadStatus.REQUEST;
                return;
            case getDatadogSettings.ok.getType():
            case updateDatadogSettings.ok.getType():
                draft.datadogLoadStatus = LoadStatus.OK;
                const datadogPayload = action.payload.response.response;
                delete datadogPayload.tenantId;
                draft.datadogSettings = datadogPayload;
                return;
            case getDatadogSettings.error.getType():
            case updateDatadogSettings.error.getType():
                draft.datadogLoadStatus = LoadStatus.ERROR;
                return;
            case getPingdomSettings.request.getType():
            case updatePingdomSettings.request.getType():
                draft.pingdomLoadStatus = LoadStatus.REQUEST;
                return;
            case getPingdomSettings.ok.getType():
            case updatePingdomSettings.ok.getType():
                draft.pingdomLoadStatus = LoadStatus.OK;
                const pingdomPayload = action.payload.response.response;
                delete pingdomPayload.tenantId;
                draft.pingdomSettings = pingdomPayload;
                return;
            case getPingdomSettings.error.getType():
            case updatePingdomSettings.error.getType():
                draft.pingdomLoadStatus = LoadStatus.ERROR;
                return;
            case getGoogleDriveSettings.request.getType():
            case createGoogleDriveSettings.request.getType():
            case updateGoogleDriveSettings.request.getType():
                draft.googleDriveLoadStatus = LoadStatus.REQUEST;
                return;
            case getGoogleDriveSettings.ok.getType():
            case createGoogleDriveSettings.ok.getType():
            case updateGoogleDriveSettings.ok.getType():
                draft.googleDriveLoadStatus = LoadStatus.OK;
                if (action.payload.response) {
                    const { enabled, folderId } = action.payload.response;
                    draft.googleDriveSettings = { enabled, folderId };
                }
                return;
            case getGoogleDriveSettings.error.getType():
            case createGoogleDriveSettings.error.getType():
            case updateGoogleDriveSettings.error.getType():
                draft.googleDriveLoadStatus = LoadStatus.ERROR;
                return;
        }
    });
}
