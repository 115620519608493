import {
    addBoard,
    addTile,
    deleteBoard,
    deleteTile,
    executeQuery,
    exportToCsv,
    getBoardByTemplate,
    getBoards,
    getFacts,
    getSegments,
    getTopics,
    resetCalculatedFieldValidation,
    updateBoard,
    updateTile,
    validateCalculatedField,
    validatePostCalculatedField,
    getBoardTiles
} from 'src/actions/dashboard/dashboard-page-actions';

import { LoadStatus } from 'src/constants/load-status';
import { pick } from 'lodash';
import produce from 'immer';

interface IDefaultState {
    startDate: null | Date;
    endDate: null | Date;
    dashboardMetricsLoadStatus: string;
    trackerDashboardMetricsLoadStatus: string;
    boardsLoadStatus: string;
    boardByTemplateLoadStatus: string;
    factsLoadStatus: string;
    saveStatus: string;
    addBoardStatus: string;
    addTileStatus: string;
    deleteBoardStatus: string;
    deleteTileStatus: string;
    topicsLoadStatus: string;
    segmentsLoadStatus: string;
    executeQueryLoadStatus: string;
    validateCalculatedFieldLoadStatus: string;
    data: Record<string, any>;
    boards: IDashboard.IBoard[];
    templatedBoards: any[];
    topics: IDashboard.ITopic[];
    segments: Record<string, IDashboard.ISegment[]>;
    facts: IDashboard.IFacts;
    queryResults: IDashboard.IQueryResults;
    calculatedFieldRows: Record<string, any>[];
    error: null | string;
    isCalculatedFieldValid: null | boolean;
    calculatedFieldDataType: string;
}

export const defaultState: IDefaultState = {
    startDate: null,
    endDate: null,
    dashboardMetricsLoadStatus: LoadStatus.EMPTY,
    trackerDashboardMetricsLoadStatus: LoadStatus.EMPTY,
    boardsLoadStatus: LoadStatus.EMPTY,
    boardByTemplateLoadStatus: LoadStatus.EMPTY,
    factsLoadStatus: LoadStatus.EMPTY,
    saveStatus: LoadStatus.EMPTY,
    addBoardStatus: LoadStatus.EMPTY,
    addTileStatus: LoadStatus.EMPTY,
    deleteBoardStatus: LoadStatus.EMPTY,
    deleteTileStatus: LoadStatus.EMPTY,
    topicsLoadStatus: LoadStatus.EMPTY,
    segmentsLoadStatus: LoadStatus.EMPTY,
    executeQueryLoadStatus: LoadStatus.EMPTY,
    validateCalculatedFieldLoadStatus: LoadStatus.EMPTY,
    data: {},
    boards: [],
    templatedBoards: [],
    topics: [],
    segments: {},
    facts: {} as IDashboard.IFacts,
    queryResults: {},
    calculatedFieldRows: [],
    error: null,
    isCalculatedFieldValid: null,
    calculatedFieldDataType: ''
};

export default function (state = defaultState, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case resetCalculatedFieldValidation.getType():
                draft.isCalculatedFieldValid = null;
                draft.calculatedFieldDataType = '';
                draft.validateCalculatedFieldLoadStatus = LoadStatus.EMPTY;
                return;
            case validatePostCalculatedField.request.getType():
                draft.validateCalculatedFieldLoadStatus = LoadStatus.REQUEST;
                return;
            case validatePostCalculatedField.ok.getType():
                const results = action.payload.response.result.rows;
                const fieldKeys = action.payload.request[1].items.map((item) => item.header);
                const validatedFieldIndex = fieldKeys.length - 1;
                draft.validateCalculatedFieldLoadStatus = LoadStatus.OK;
                draft.isCalculatedFieldValid = results.length > 0;
                draft.calculatedFieldRows = results.map((row) => pick(row, [fieldKeys[validatedFieldIndex]]));
                draft.calculatedFieldDataType = action.payload.response.result.dataTypes[validatedFieldIndex];
                return;
            case validatePostCalculatedField.error.getType():
                draft.validateCalculatedFieldLoadStatus = LoadStatus.ERROR;
                draft.isCalculatedFieldValid = false;
                return;
            case exportToCsv.request.getType():
                draft.saveStatus = LoadStatus.REQUEST;
                return;
            case exportToCsv.ok.getType():
                draft.saveStatus = LoadStatus.OK;
                return;
            case exportToCsv.error.getType():
                draft.saveStatus = LoadStatus.ERROR;
                return;
            case validateCalculatedField.request.getType():
                draft.validateCalculatedFieldLoadStatus = LoadStatus.REQUEST;
                return;
            case validateCalculatedField.ok.getType():
                draft.validateCalculatedFieldLoadStatus = LoadStatus.OK;
                draft.isCalculatedFieldValid = action.payload.response.result.sampleRows.length > 0;
                draft.calculatedFieldRows = action.payload.response.result.sampleRows;
                draft.calculatedFieldDataType = action.payload.response.result.dataType;
                return;
            case validateCalculatedField.error.getType():
                draft.validateCalculatedFieldLoadStatus = LoadStatus.ERROR;
                draft.isCalculatedFieldValid = false;
                return;
            case executeQuery.request.getType():
                draft.executeQueryLoadStatus = LoadStatus.REQUEST;
                return;
            case executeQuery.ok.getType():
                const tileId = action.payload.request[2];
                draft.executeQueryLoadStatus = LoadStatus.OK;
                draft.queryResults = {
                    ...draft.queryResults,
                    ...{ [tileId]: action.payload.response.result }
                };
                return;
            case executeQuery.error.getType():
                draft.executeQueryLoadStatus = LoadStatus.ERROR;
                return;
            case getFacts.request.getType():
                draft.factsLoadStatus = LoadStatus.REQUEST;
                return;
            case getFacts.ok.getType():
                draft.factsLoadStatus = LoadStatus.OK;
                draft.facts = action.payload.response.facts;
                return;
            case getFacts.error.getType():
                draft.factsLoadStatus = LoadStatus.ERROR;
                return;
            case getSegments.request.getType():
                draft.segmentsLoadStatus = LoadStatus.REQUEST;
                return;
            case getSegments.ok.getType():
                draft.segmentsLoadStatus = LoadStatus.OK;
                draft.segments = {
                    ...draft.segments,
                    ...{
                        [action.payload.request[0]]: action.payload.response.segments
                    }
                };
                return;
            case getSegments.error.getType():
                draft.segmentsLoadStatus = LoadStatus.ERROR;
                return;
            case getTopics.request.getType():
                draft.topicsLoadStatus = LoadStatus.REQUEST;
                return;
            case getTopics.ok.getType():
                draft.topicsLoadStatus = LoadStatus.OK;
                draft.topics = action.payload.response.topics;
                return;
            case getTopics.error.getType():
                draft.topicsLoadStatus = LoadStatus.ERROR;
                return;
            case updateTile.request.getType():
                draft.saveStatus = LoadStatus.REQUEST;
                return;
            case updateTile.ok.getType():
                draft.saveStatus = LoadStatus.OK;
                draft.boards = draft.boards.map((board) => {
                    if (board._id === action.payload.request[0]) {
                        board.tiles = board.tiles.map((tile) => {
                            if (tile._id === action.payload.response.tile._id) {
                                return action.payload.response.tile;
                            }
                            return tile;
                        });
                    }
                    return board;
                });
                return;
            case updateTile.error.getType():
                draft.saveStatus = LoadStatus.ERROR;
                return;
            case deleteTile.request.getType():
                draft.deleteTileStatus = LoadStatus.REQUEST;
                return;
            case deleteTile.ok.getType():
                draft.deleteTileStatus = LoadStatus.OK;
                draft.boards = draft.boards.map((board) => {
                    if (board._id === action.payload.request[0]) {
                        board.tiles = [...board.tiles.filter((tile) => tile._id !== action.payload.response.tile._id)];
                    }
                    return board;
                });
                return;
            case deleteTile.error.getType():
                draft.deleteTileStatus = LoadStatus.ERROR;
                return;
            case addTile.request.getType():
                draft.addTileStatus = LoadStatus.REQUEST;
                return;
            case addTile.ok.getType():
                draft.addTileStatus = LoadStatus.OK;
                draft.boards = draft.boards.map((board) => {
                    if (board._id === action.payload.request[0]) {
                        board.tiles = board.tiles.concat(action.payload.response.tile);
                    }
                    return board;
                });
                return;
            case addTile.error.getType():
                draft.addTileStatus = LoadStatus.ERROR;
                return;
            case addBoard.request.getType():
                draft.addBoardStatus = LoadStatus.REQUEST;
                return;
            case addBoard.ok.getType():
                draft.addBoardStatus = LoadStatus.OK;
                draft.boards.push(action.payload.response.board);
                return;
            case addBoard.error.getType():
                draft.addBoardStatus = LoadStatus.ERROR;
                return;
            case updateBoard.request.getType():
                draft.saveStatus = LoadStatus.REQUEST;
                return;
            case updateBoard.ok.getType():
                draft.saveStatus = LoadStatus.OK;
                draft.boards = draft.boards.map((board) => {
                    if (board._id === action.payload.response.board._id) {
                        return action.payload.response.board;
                    }
                    return board;
                });
                return;
            case updateBoard.error.getType():
                draft.saveStatus = LoadStatus.ERROR;
                return;
            case deleteBoard.request.getType():
                draft.deleteBoardStatus = LoadStatus.REQUEST;
                return;
            case deleteBoard.ok.getType():
                draft.deleteBoardStatus = LoadStatus.OK;
                draft.boards = [...state.boards.filter((board) => board._id !== action.payload.response.board._id)];
                return;
            case deleteBoard.error.getType():
                draft.deleteBoardStatus = LoadStatus.ERROR;
                return;
            case getBoardByTemplate.request.getType():
                draft.boardByTemplateLoadStatus = LoadStatus.REQUEST;
                return;
            case getBoardByTemplate.ok.getType():
                const boardId = action.payload.response.template[0]._id;
                draft.boardByTemplateLoadStatus = LoadStatus.OK;
                draft.templatedBoards = {
                    ...state.templatedBoards,
                    [boardId]: action.payload.response.template
                };
                return;
            case getBoardByTemplate.error.getType():
                draft.boardByTemplateLoadStatus = LoadStatus.ERROR;
                return;
            case getBoards.request.getType():
                draft.boardsLoadStatus = LoadStatus.REQUEST;
                return;
            case getBoards.ok.getType():
                draft.boardsLoadStatus = LoadStatus.OK;
                draft.boards = action.payload.response.boards;
                return;
            case getBoards.error.getType():
                draft.boardsLoadStatus = LoadStatus.ERROR;
                return;
            case getBoardTiles.request.getType():
                draft.boardsLoadStatus = LoadStatus.REQUEST;
                return;
            case getBoardTiles.ok.getType():
                draft.boardsLoadStatus = LoadStatus.OK;
                draft.boards = draft.boards.map((board) => {
                    if (board._id === action.payload.response.board._id) {
                        const cleanTiles = action.payload.response.board.tiles.map((tile) => {
                            const { query, visualization } = tile;
                            if (visualization) {
                                const { x, y } = visualization.axes;
                                const newX = x.filter((axisItem) =>
                                    query.items.some((item) => item.header === axisItem)
                                );
                                const newY = y.filter((axisItem) =>
                                    query.items.some((item) => item.header === axisItem)
                                );
                                return {
                                    ...tile,
                                    visualization: {
                                        ...visualization,
                                        axes: {
                                            ...visualization.axes,
                                            x: newX,
                                            y: newY
                                        }
                                    }
                                };
                            }
                            return tile;
                        });
                        return { ...board, tiles: cleanTiles };
                    }
                    return board;
                });
                return;
            case getBoardTiles.error.getType():
                draft.boardsLoadStatus = LoadStatus.ERROR;
                return;
        }
    });
}
