import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import withSentryErrorBoundary from 'src/components/hocs/sentryErrorBoundary';
import { getBlamelessId } from 'src/actions/auth/auth-actions';
import { getUserRoles, getUserIntegrations } from 'src/actions/identity-management/identity-management-actions';
import { LoadStatus } from 'src/constants/load-status';
import { ROLE_COMPONENTS, ROOT_ROLE, ALL_INTEGRATIONS, PERMISSION_ACTION } from 'src/constants/user-permissions';
import { isEqual } from 'lodash';
import { singletonHook } from 'react-singleton-hook';

export const useUserPermissionsHook = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const getUserRolesLoadStatus = useSelector((state: RootState) => state.identityManagement.getUserRolesLoadStatus);
    const getUserIntegrationsLoadStatus = useSelector(
        (state: RootState) => state.identityManagement.getUserIntegrationsLoadStatus
    );
    const userRoles = useSelector((state: RootState) => state.identityManagement.userRoles, isEqual);
    const userIntegrations = useSelector((state: RootState) => state.identityManagement.userIntegrations, isEqual);

    const getBlamelessIdLoadStatus = useSelector((state: RootState) => state.auth.getBlamelessIdLoadStatus);
    const isDeactivatedUser = useSelector((state: RootState) => state.auth.isDeactivatedUser);
    const [userComponents, setUserComponents] = useState<IUserPermissions.IUserComponents>(
        Object.values(ROLE_COMPONENTS).reduce((prev, current) => {
            return {
                ...prev,
                [current]: Array.isArray(userRoles)
                    ? userRoles.some((role: IUserPermissions.IUserRole) => role.component === current)
                    : false
            };
        }, {})
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isDeactivatedUser) {
            dispatch(getBlamelessId());
        }
    }, [dispatch, isDeactivatedUser]);

    useEffect(() => {
        if (getUserRolesLoadStatus === LoadStatus.EMPTY) {
            dispatch(getUserRoles());
        }
    }, [dispatch, getUserRolesLoadStatus]);

    useEffect(() => {
        if (getUserIntegrationsLoadStatus === LoadStatus.EMPTY) {
            dispatch(getUserIntegrations());
        }
    }, [dispatch, getUserIntegrationsLoadStatus]);

    useEffect(() => {
        const newUserComponents = Object.values(ROLE_COMPONENTS).reduce((prev, current) => {
            return {
                ...prev,
                [current]: userRoles.some((role: IUserPermissions.IUserRole) => role.component === current)
            };
        }, {});
        setUserComponents(newUserComponents);
    }, [userRoles]);

    useEffect(() => {
        setIsError(
            getBlamelessIdLoadStatus === LoadStatus.ERROR ||
                getUserRolesLoadStatus === LoadStatus.ERROR ||
                getUserIntegrationsLoadStatus === LoadStatus.ERROR
        );
        setIsLoading(
            getBlamelessIdLoadStatus === LoadStatus.REQUEST ||
                getUserRolesLoadStatus === LoadStatus.REQUEST ||
                getUserIntegrationsLoadStatus === LoadStatus.REQUEST
        );
    }, [getBlamelessIdLoadStatus, getUserRolesLoadStatus, getUserIntegrationsLoadStatus]);

    const getUserActionsByComponent = (
        component: IUserPermissions.ROLE_COMPONENTS,
        incidentTypeName?: string
    ): Record<PERMISSION_ACTION, boolean> => {
        const roles = userRoles;
        const hasRootAccess = !!roles.find(
            (role: IUserPermissions.IUserRole) => role.component === ROLE_COMPONENTS.ALL
        );

        // If incidentTypeName is provided, we need to check if the user has access on those specific incident types, otherwise we consider all incident types
        const filteredRoles = incidentTypeName
            ? roles.filter(
                  (role) =>
                      role.component !== ROLE_COMPONENTS.INCIDENT ||
                      (role.component === ROLE_COMPONENTS.INCIDENT &&
                          role.name.toLowerCase().startsWith(`${incidentTypeName.toLowerCase()}incident`))
              )
            : roles;
        // For incident component, we need to check if the user has access on any of the incident type roles
        const userActions = Object.values(PERMISSION_ACTION).reduce((prev, current) => {
            return {
                ...prev,
                [current]:
                    hasRootAccess ||
                    filteredRoles.some(
                        (role: IUserPermissions.IUserRole) =>
                            role.component === component &&
                            role.actions.some((action) => action === PERMISSION_ACTION.ALL || action === current)
                    )
            };
        }, {}) as Record<PERMISSION_ACTION, boolean>;
        return userActions;
    };

    return {
        isError,
        isLoading,
        isDeactivatedUser,
        userComponents,
        userIntegrations: userIntegrations.map(({ id }) => id),
        userRoles: userRoles,
        getUserActionsByComponent
    };
};

const useUserPermissions = singletonHook(
    {
        isError: false,
        isLoading: true,
        isDeactivatedUser: false,
        userComponents: [{ [ROLE_COMPONENTS.ALL]: true }],
        userIntegrations: ALL_INTEGRATIONS,
        userRoles: [ROOT_ROLE],
        getUserActionsByComponent: () => ({
            '*': true,
            create: true,
            delete: true,
            execute: true,
            read: true,
            update: true
        })
    },
    useUserPermissionsHook
);

interface IProps {
    children: ({
        isError,
        isLoading,
        isDeactivatedUser,
        userComponents,
        userRoles,
        userIntegrations,
        getUserActionsByComponent
    }: IUserPermissions.IUserPermissionsProps) => JSX.Element;
}

const UserPermissions = ({ children }: IProps) => children(useUserPermissions());
// This version is for testing since react-singleton-hook has issues with unmounting provider
const UserPermissionsForTesting = ({ children }: IProps) => children(useUserPermissionsHook());

export default withSentryErrorBoundary(UserPermissions);
export { useUserPermissions, UserPermissionsForTesting };
