export const REF_PREFIX = 'ref:field-';

export const INCIDENT_DURATION_TYPES = {
    duration_of_customer_impact: 'Times to Resolution',
    time_to_identification: 'Times to Identification',
    time_to_take_action: 'Times to take Action'
};

export const DASHBOARD_CHART_BREAKPOINT = 600;

export const TABLE_PAGINATION_OPTIONS = [1, 5, 10, 25, 50, 100, 250, 500, 1000];

export const BLANK_BOARD: { name: string; tiles: IDashboard.ITile[] } = {
    name: 'New Board',
    tiles: []
};

export const SEGMENTS = {
    INCIDENT: 'Incident',
    POSTMORTEM: 'Retrospective',
    TAG: 'Tag',
    TASK: 'Task',
    ACTION: 'Action',
    EVENT: 'Event',
    COMMSLEAD: 'Comms Lead'
};

export const DATA_TYPE = {
    TEXT: 'text',
    NUMBER: 'number',
    DATETIME: 'datetime',
    BOOLEAN: 'boolean'
};

export const VISUALIZATION_TYPE = {
    BAR: {
        name: 'bar',
        label: 'Bar'
    },
    STACKED_BAR: {
        name: 'stackedBar',
        label: 'Stacked Bar'
    },
    STACKED_BAR_HORIZONTAL: {
        name: 'stackedBarHorizontal',
        label: 'Horizontal Stacked Bar'
    },
    LINE: {
        name: 'line',
        label: 'Line'
    },
    MULTILINE: {
        name: 'multiline',
        label: 'Multi Line'
    },
    SCATTER: {
        name: 'scatter',
        label: 'Scatter'
    },
    AREA: {
        name: 'area',
        label: 'Area'
    },
    RADAR: {
        name: 'radar',
        label: 'Radar'
    },
    PIE: {
        name: 'pie',
        label: 'Pie'
    },
    TABLE: {
        name: 'table',
        label: 'Data table'
    },
    DATA_TILE: {
        name: 'dataTile',
        label: 'Data Tile'
    }
};

export const AGGREGATE_TYPES = {
    CONCATENATE: 'concatenate',
    LESS_THAN_PERCENTILE: 'less_than_percentile'
};

export const FORMAT_TYPES = {
    INCIDENT_URL: 'incident_url',
    LINK: 'link',
    IMAGE_URL: 'image_url'
};

export const SORT_TYPES = [
    { name: 'asc', label: 'Ascending' },
    { name: 'desc', label: 'Descending' }
];

export const FILTER_TYPES = {
    ROLLING_WINDOW: 'rw',
    EQUALS: 'eq',
    NOT_EQUALS: '!eq',
    CONTAINS: 'ct',
    NOT_CONTAINS: '!ct',
    STARTS_WITH: 'sw',
    ENDS_WITH: 'ew',
    FROM_LIST: 'in',
    NOT_FROM_LIST: '!in',
    GREATER_THAN: 'gt',
    GREATER_THAN_OR_EQUAL_TO: 'gte',
    LESS_THAN: 'lt',
    LESS_THAN_OR_EQUAL_TO: 'lte',
    BETWEEN: 'btw',
    EMPTY: 'empty',
    NOT_EMPTY: '!empty',
    LESS_THAN_PERCENTILE: 'less_than_percentile'
};

export const NEW_BOARD_TYPES = {
    CLONE_CURRENT: 'CLONE_CURRENT',
    CLONE_EXISTING: 'CLONE_EXISTING',
    BLANK: 'BLANK'
};

export const TILE_CONSTRAINTS = {
    MAX_W: 16777216,
    MAX_H: 16777216,
    MIN_W: 2,
    MIN_H: 5
};

export const ADDITIONAL_LEGEND_COLORS = [
    '#009cd2',
    '#fc7753',
    '#fed766',
    '#13b479',
    '#50514f',
    '#004f71',
    '#ff7b78',
    '#fcc671',
    '#7189ff',
    '#0077aa',
    '#e85653',
    '#1e8b70',
    '#ffcaaf',
    '#4858a3'
];

export const ROLLING_WINDOW_LAYOUTS = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical'
};

export const FIELD_REGEX_TERMINATOR = '( )?[^\\*\\%\\^\\>\\<\\(\\)\\/\\-\\+a-zA-Z,"0-9]?';

export const RANGE_TYPES = {
    LAST_WEEK: 'last week',
    LAST_MONTH: 'last month',
    LAST_3_MONTH: 'last 3 month',
    LAST_YEAR: 'last year',
    CUSTOM: 'custom'
};

export const MODALS = {
    SET_TOPIC: 'setTopic',
    EDIT_BOARD: 'editBoard',
    DELETE_BOARD: 'deleteBoard'
};

export const TOPIC_NAMES = {
    INCIDENT: 'incident',
    SLO: 'slo',
    AUDIT: 'audit',
    IDENTITY: 'identity',
    COMMENT: 'comment'
};

export const FIELDS_LIMIT = 10;

export const RI_TIMEZONE_LOCALSTORAGE = 'RI_TIMEZONE_LOCALSTORAGE';
