export const EVENTS = {
    LOGIN_SUCCESSFUL: 'login_successful',

    DASHBOARD_NEW_BOARD: 'dashboard_new_board',
    DASHBOARD_CLONE_BOARD: 'dashboard_clone_board',
    DASHBOARD_EDIT_BOARD: 'dashboard_edit_board',
    DASHBOARD_DELETE_BOARD: 'dashboard_delete_board',

    DASHBOARD_CLONE_TILE: 'dashboard_clone_tile',
    DASHBOARD_CLONE_TILE_TO_BOARD: 'dashboard_clone_tile_to_board',
    DASHBOARD_MOVE_TILE_TO_BOARD: 'dashboard_move_tile_to_board',
    DASHBOARD_ADD_TILE: 'dashboard_add_tile',
    DASHBOARD_EDIT_TILE: 'dashboard_edit_tile',
    DASHBOARD_REMOVE_TILE: 'dashboard_remove_tile',

    DASHBOARD_GO_FULLSCREEN: 'dashboard_go_fullscreen',

    DASHBOARD_EXPORT_CSV: 'dashboard_export_csv',
    DASHBOARD_EXPORT_API: 'dashboard_export_api',
    DASHBOARD_EXPORT_SVG: 'dashboard_export_svg',
    DASHBOARD_EXPORT_PNG: 'dashboard_export_png',
    DASHBOARD_EXPORT_PDF: 'dashboard_export_pdf',
    DASHBOARD_EXPORT_JPEG: 'dashboard_export_jpeg',

    DASHBOARD_QUERY_BUILDER_ADD_FIELD: 'dashboard_query_builder_add_field',
    DASHBOARD_QUERY_BUILDER_REMOVE_FIELD: 'dashboard_query_builder_remove_field',
    DASHBOARD_QUERY_BUILDER_REFINE_FIELD: 'dashboard_query_builder_refine_field',
    DASHBOARD_QUERY_BUILDER_SAVE_VISUALIZATION: 'dashboard_query_builder_save_visualization',
    DASHBOARD_QUERY_BUILDER_APPLY_FORMATTING: 'dashboard_query_builder_apply_formatting',
    DASHBOARD_QUERY_BUILDER_APPLY_AGGREGATION: 'dashboard_query_builder_apply_aggregation',
    DASHBOARD_QUERY_BUILDER_APPLY_FILTER: 'dashboard_query_builder_apply_filter',
    DASHBOARD_QUERY_BUILDER_APPLY_SORTING: 'dashboard_query_builder_apply_sorting',

    INCIDENT_TYPE_CREATED: 'incident_type_created'
};
