import { getMsTeamsTeams } from 'src/actions/msteams/msteams-actions';
import { getMsTeamsUsers } from 'src/actions/msteams/msteams-actions';

import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    loadMsTeamsTeamsStatus: string;
    loadMsTeamsUsersStatus: string;
    msTeamsTeams: MsTeams.Team[];
    msTeamsUsers: MsTeams.User[];
}

export const defaultState: IDefaultState = {
    loadMsTeamsTeamsStatus: LoadStatus.EMPTY,
    loadMsTeamsUsersStatus: LoadStatus.EMPTY,
    msTeamsTeams: [],
    msTeamsUsers: []
};

export default function (state: IDefaultState = defaultState, action: Common.IReducerAction) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getMsTeamsTeams.request.getType():
                draft.loadMsTeamsTeamsStatus = LoadStatus.REQUEST;
                return;
            case getMsTeamsTeams.ok.getType():
                draft.msTeamsTeams = action.payload.response.groups;
                draft.loadMsTeamsTeamsStatus = LoadStatus.OK;
                return;
            case getMsTeamsTeams.error.getType():
                draft.loadMsTeamsTeamsStatus = LoadStatus.ERROR;
                return;
            case getMsTeamsUsers.request.getType():
                draft.loadMsTeamsUsersStatus = LoadStatus.REQUEST;
                return;
            case getMsTeamsUsers.ok.getType():
                draft.msTeamsUsers = action.payload.response.users;
                draft.loadMsTeamsUsersStatus = LoadStatus.OK;
                return;
            case getMsTeamsUsers.error.getType():
                draft.loadMsTeamsUsersStatus = LoadStatus.ERROR;
                return;
        }
    });
}
