import { createActionAsync } from 'redux-act-async';
import { createAction, Dispatch } from 'redux-act';
import { enqueueSnackbar } from 'src/actions/notification/notification-actions';
import {
    setJiraProject,
    setJiraIssueType,
    setJiraFollowUpIssueType,
    setJiraRequiredFields,
    setJiraFollowupProject,
    setJiraIssueTypes,
    setJiraFollowUpIssueTypes
} from 'src/actions/json-schema/json-schema-actions';
import { SCHEMA_FIELDS, REQUIRED_FIELDS_TYPES } from 'src/constants/schema-fields';
import $api from 'src/utils/api-interface';
import { IPreClickResponse } from 'src/components/settings/integration/settings-oauth';
import { CancelTokenSource } from 'axios';

export const GET_SETTINGS_SECTION_MENU_DATA = 'GET_SETTINGS_SECTION_MENU_DATA';
export const getSettingsSectionMenuData = () => {
    return (dispatch) => {
        if (
            !dispatch({
                type: GET_SETTINGS_SECTION_MENU_DATA,
                payload: {},
                meta: {
                    id: GET_SETTINGS_SECTION_MENU_DATA,
                    done: false
                }
            })
        ) {
            return;
        }

        $api.get({
            url: '/settings/metadata'
        })
            .then((res) =>
                dispatch({
                    type: GET_SETTINGS_SECTION_MENU_DATA,
                    payload: res.data,
                    meta: {
                        id: GET_SETTINGS_SECTION_MENU_DATA,
                        done: true
                    }
                })
            )
            .catch((error) =>
                dispatch({
                    type: GET_SETTINGS_SECTION_MENU_DATA,
                    error,
                    meta: {
                        id: GET_SETTINGS_SECTION_MENU_DATA,
                        done: true
                    }
                })
            );
    };
};

const dispatchJiraFieldActions = (data, dispatch) => {
    return new Promise((resolve) => {
        const { settings } = data;
        if (settings) {
            settings.forEach((setting) => {
                if (setting.name === SCHEMA_FIELDS.JIRA_TICKET_PROJECT) {
                    dispatch(
                        setJiraProject(
                            setting.currentValue,
                            setting.currentDisplayValue ?? `${setting.currentValue} Not Found`
                        )
                    );
                }
                if (setting.name === SCHEMA_FIELDS.JIRA_TICKET_TYPE) {
                    dispatch(
                        setJiraIssueType(
                            setting.currentValue && setting.currentDisplayValue ? setting.currentValue : ''
                        )
                    );
                    dispatch(setJiraIssueTypes(setting.uiValueOptions));
                }
                if (setting.name === SCHEMA_FIELDS.JIRA_FOLLOWUP_TICKET_PROJECT) {
                    dispatch(setJiraFollowupProject(setting.currentValue, setting.currentDisplayValue));
                }
                if (setting.name === SCHEMA_FIELDS.JIRA_FOLLOWUP_TICKET_TYPE) {
                    dispatch(
                        setJiraFollowUpIssueType(
                            setting.currentValue && setting.currentDisplayValue ? setting.currentValue : ''
                        )
                    );
                    dispatch(setJiraFollowUpIssueTypes(setting.uiValueOptions));
                }

                if (Object.keys(setting)[0] === SCHEMA_FIELDS.JIRA_REQUIRED_FIELDS) {
                    const requiredFields = setting[SCHEMA_FIELDS.JIRA_REQUIRED_FIELDS];
                    dispatch(
                        setJiraRequiredFields(
                            requiredFields.filter(
                                (field) => !field.name.includes(`${REQUIRED_FIELDS_TYPES.followup}.summary`)
                            )
                        )
                    );
                }
            });
        }
        resolve(undefined);
    });
};

export const getSettingsScopeSectionsByRoles = createActionAsync(
    'GET_SETTINGS_SCOPE_SECTIONS_BY_ROLES',
    (blamelessId: number, action: string) =>
        $api
            .get({
                url: 'identity/rbac/params',
                params: {
                    userID: blamelessId,
                    action,
                    resource: 'setting',
                    paramKey: 'scopeSection'
                }
            })
            .then((res) => res.data.response)
            .catch((error) => {
                throw error;
            })
);

export const GET_SETTING_SECTION = 'GET_SETTING_SECTION';
export const getSettingsSectionWithSub = (
    sectionId: string,
    subSectionId: string | null,
    entityScopeType: string,
    entityScopeId: string,
    cancelToken?: CancelTokenSource
) => {
    return (dispatch) => {
        const url = subSectionId ? `/settings/${sectionId}/${subSectionId}` : `/settings/${sectionId}`;
        const dispatchObject: Record<string, any> = {
            type: GET_SETTING_SECTION,
            payload: {},
            error: {},
            meta: {
                id: GET_SETTING_SECTION,
                done: false,
                reqId: `${sectionId} ${subSectionId} ${entityScopeType} ${entityScopeId}`
            }
        };
        if (!dispatch(dispatchObject)) {
            return;
        }
        $api.get({
            url,
            params: {
                entityScopeType,
                entityScopeId
            },
            cancelToken: cancelToken?.token
        })
            .then((res) => {
                dispatchObject.error = undefined;
                dispatchObject.payload = res.data;
                dispatchObject.meta.done = true;
                dispatchJiraFieldActions(res.data, dispatch).then(() => dispatch(dispatchObject));
            })
            .catch((error) => {
                dispatchObject.payload = undefined;
                dispatchObject.error = error;
                dispatchObject.meta.done = true;
                dispatch(dispatchObject);
            });
    };
};

export const SET_IS_MIGRATION_WEBHOOK_MODAL = 'SET_IS_MIGRATION_WEBHOOK_MODAL';

export const setIsMigrationWebhookModal = createAction<{ isMigrationWebhookModal: boolean }>(
    'SET_IS_MIGRATION_WEBHOOK_MODAL'
);
export const SET_IS_CREATE_WEBHOOK_MODAL = 'SET_IS_CREATE_WEBHOOK_MODAL';

export const setIsCreateWebhookModal = createAction<{ isCreateWebhookModal: boolean }>('SET_IS_CREATE_WEBHOOK_MODAL');

export const GET_GOOGLE_CALENDAR_OAUTH_URL = 'GET_GOOGLE_CALENDAR_OAUTH_URL';
export const getGoogleCalendarOAuthUrl = () => {
    return (dispatch) => {
        const dispatchObject: Record<string, any> = {
            type: GET_GOOGLE_CALENDAR_OAUTH_URL,
            payload: {},
            error: {},
            meta: {
                id: GET_GOOGLE_CALENDAR_OAUTH_URL,
                done: false
            }
        };
        if (!dispatch(dispatchObject)) {
            return;
        }
        $api.get({ url: '/google-calendar-oauth-url' })
            .then((res) => {
                delete dispatchObject.error;
                dispatchObject.payload = res.data;
                dispatchObject.meta.done = true;
                dispatch(dispatchObject);
            })
            .catch((error) => {
                delete dispatchObject.payload;
                dispatchObject.error = error;
                dispatchObject.meta.done = true;
                dispatch(dispatchObject);
            });
    };
};

export const GET_SLACK_OAUTH_URL = 'GET_SLACK_OAUTH_URL';
export const getSlackOAuthUrl = (openSlackApp = false) => {
    return (dispatch) => {
        const dispatchObject: Record<string, any> = {
            type: GET_SLACK_OAUTH_URL,
            payload: {},
            error: {},
            meta: {
                id: GET_SLACK_OAUTH_URL,
                done: false
            }
        };

        if (!dispatch(dispatchObject)) {
            return;
        }

        let url = 'slack-oauth-url';

        if (openSlackApp) {
            url = url + '?open_slack_app';
        }

        return $api
            .get({ url })
            .then((res) => {
                delete dispatchObject.error;
                dispatchObject.payload = res.data;
                dispatchObject.meta.done = true;
                dispatch(dispatchObject);
                return res.data;
            })
            .catch((error) => {
                delete dispatchObject.payload;
                dispatchObject.error = error;
                dispatchObject.meta.done = true;
                dispatch(dispatchObject);
            });
    };
};

export const GET_MICROSOFT_OAUTH_URL = 'GET_MICROSOFT_OAUTH_URL';
export const getMicrosoftOAuthUrl = () => {
    return (dispatch) => {
        const dispatchObject: Record<string, any> = {
            type: GET_MICROSOFT_OAUTH_URL,
            payload: {},
            error: {},
            meta: {
                id: GET_MICROSOFT_OAUTH_URL,
                done: false
            }
        };

        if (!dispatch(dispatchObject)) {
            return;
        }

        $api.get({ url: 'microsoft-oauth-url' })
            .then((res) => {
                delete dispatchObject.error;
                dispatchObject.payload = res.data;
                dispatchObject.meta.done = true;
                dispatch(dispatchObject);
            })
            .catch((error) => {
                delete dispatchObject.payload;
                dispatchObject.error = error;
                dispatchObject.meta.done = true;
                dispatch(dispatchObject);
            });
    };
};

export const GET_MICROSOFT_MEETING_OAUTH_URL = 'GET_MICROSOFT_MEETING_OAUTH_URL';
export const getMicrosoftMeetingOAuthUrl = () => {
    return (dispatch) => {
        const dispatchObject: Record<string, any> = {
            type: GET_MICROSOFT_MEETING_OAUTH_URL,
            payload: {},
            error: {},
            meta: {
                id: GET_MICROSOFT_MEETING_OAUTH_URL,
                done: false
            }
        };

        if (!dispatch(dispatchObject)) {
            return;
        }

        $api.get({ url: 'microsoft-meeting-oauth-url' })
            .then((res) => {
                delete dispatchObject.error;
                dispatchObject.payload = res.data;
                dispatchObject.meta.done = true;
                dispatch(dispatchObject);
            })
            .catch((error) => {
                delete dispatchObject.payload;
                dispatchObject.error = error;
                dispatchObject.meta.done = true;
                dispatch(dispatchObject);
            });
    };
};

export const GET_GOTOMEETING_OAUTH_URL = 'GET_GOTOMEETING_OAUTH_URL';
export const getGoToMeetingOAuthUrl = () => {
    return (dispatch) => {
        const dispatchObject: Record<string, any> = {
            type: GET_GOTOMEETING_OAUTH_URL,
            payload: {},
            error: {},
            meta: {
                id: GET_GOTOMEETING_OAUTH_URL,
                done: false
            }
        };
        if (!dispatch(dispatchObject)) {
            return;
        }
        $api.get({ url: '/gotomeeting-oauth-url' })
            .then((res) => {
                delete dispatchObject.error;
                dispatchObject.payload = res.data;
                dispatchObject.meta.done = true;
                dispatch(dispatchObject);
            })
            .catch((error) => {
                delete dispatchObject.payload;
                dispatchObject.error = error;
                dispatchObject.meta.done = true;
                dispatch(dispatchObject);
            });
    };
};

export const POST_JIRA_OAUTH_ACCESS_CODE = 'POST_JIRA_OAUTH_ACCESS_CODE';
export const postJiraOAuthAuthorized = (oauthAccessCode: string) => {
    return () => {
        return new Promise<{ ok: boolean; errorMsg: string }>((resolve, reject) => {
            $api.post({ url: '/request-jira-oauth-access-token', data: { oauthAccessCode } })
                .then((res) => resolve(res.data))
                .catch((error) => {
                    reject({
                        ok: false,
                        message: error.message
                            ? error.message
                            : 'Could not get a valid access token from JIRA, please try again later.'
                    });
                });
        });
    };
};

export const GET_JIRA_OAUTH_URL = 'GET_JIRA_OAUTH_URL';
export const getJiraOAuthUrl = () => {
    return (dispatch): Promise<IPreClickResponse> => {
        const ERROR_MESSAGE = 'Unable to reach your Jira server/account, please check your settings and try again';

        const getUserMessage = (backMsg: string): string => {
            //Jira instance
            const jiraDisabledCode = `Jira OAuth wasn't initialized, save settings first`;
            const jiraServerCode = 'Jira server is not found';
            const keyErrorCode = 'Unknown Jira exception';
            const consumerKeyCode = 'Jira error! Consumer key is unknown';
            const keyError =
                'The provided public and/or private RSA keys do not seem to match, please check your settings and try again';

            switch (backMsg) {
                case jiraServerCode:
                    return 'Unable to reach your Jira server/account, please check your settings and try again';
                case consumerKeyCode:
                    return 'The provided Consumer key does not match, please check your settings and try again';
                case jiraDisabledCode:
                    return `Jira OAuth wasn't initialized, save settings first`;
                case keyErrorCode:
                    return keyError;
                default:
                    return `Unable to complete the authorization with Jira, please check your settings and try again`;
            }
        };

        const showErrorSnack = (message: string) => {
            dispatch(
                enqueueSnackbar({
                    message,
                    options: {
                        variant: 'error'
                    }
                })
            );
        };
        return new Promise<IPreClickResponse>((resolve, reject) => {
            $api.post({ url: '/request-jira-oauth-request-token' })
                .then((res) => {
                    if (res.data.ok) {
                        $api.get({ url: '/jira-oauth-url' })
                            .then((res) => {
                                if (res.data.ok) {
                                    resolve(res.data);
                                } else {
                                    //Error must be coded
                                    const msgUserFriendly = getUserMessage(res.data.errorMsg);
                                    showErrorSnack(msgUserFriendly);
                                    reject({ ok: false, message: msgUserFriendly });
                                }
                            })
                            .catch(() => {
                                showErrorSnack(ERROR_MESSAGE);
                                reject({ ok: false });
                            });
                    } else {
                        const msgUserFriendly = getUserMessage(res.data.errorMsg);
                        showErrorSnack(msgUserFriendly);
                        reject({ ok: false, message: msgUserFriendly });
                    }
                })
                .catch(() => {
                    showErrorSnack(ERROR_MESSAGE);
                    reject({ ok: false });
                });
        });
    };
};

export const snowIntegrationHealthTest = createActionAsync('GET_SNOW_INFO', (messages, dispatch) => {
    const showErrorSnack = (message: string) => {
        dispatch(
            enqueueSnackbar({
                message,
                options: {
                    variant: 'error'
                }
            })
        );
    };

    return $api
        .get({
            url: 'servicenow/info'
        })
        .then((res) => {
            if (res.data.ok) {
                const { info } = res.data;
                if (info.enabled) {
                    if (info.probe.healthy) {
                        dispatch(
                            enqueueSnackbar({
                                message: messages.onSuccess,
                                options: {
                                    variant: 'success'
                                }
                            })
                        );
                    } else {
                        showErrorSnack(info.probe.degradation);
                    }
                } else {
                    showErrorSnack(`${messages.connectionError} ${messages.integrationDisabled}`);
                }
            } else {
                showErrorSnack(messages.connectionError + res.data.errorMsg);
            }
            return res.data;
        })
        .catch((error) => {
            showErrorSnack(`${messages.connectionError} ${messages.wrongConfiguration}`);
            throw error;
        });
});

export const getAutocompleteOptions = createActionAsync('GET_AUTOCOMPLETE_OPTIONS', (baseUrl, queryParams) =>
    $api
        .get({
            url: baseUrl,
            params: queryParams
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const setSettingsLoadStatus = createAction<{ loadStatus: string }>('CHANGE_SETTINGS_LOAD_STATUS');

export const setAreErrorsMuted = createAction<{ areErrorsMuted: boolean }>('SET_ARE_ERRORS_MUTED');

export const createIncidentType = createActionAsync('CREATE_INCIDENT_TYPE', (data, userId, sectionName, dispatch) => {
    return $api
        .post({
            url: '/settings/incident-type',
            params: {
                userId,
                name: sectionName
            },
            data: {
                ...data,
                name: data.name
            }
        })
        .then((res) => {
            dispatch(setSettingsSection(data?.settings));
            dispatch(
                enqueueSnackbar({
                    message: `Successfully created new incidenrt type ${data.name} `,
                    options: {
                        variant: 'success'
                    }
                })
            );
            return res.data;
        })
        .catch((error) => {
            if (!data?.muteSettingsErrors) {
                dispatch(
                    enqueueSnackbar({
                        message: `Unsuccessfully incident type creation: ${data.name} `,
                        options: {
                            variant: 'error'
                        }
                    })
                );
            }
            throw error;
        });
});

export const updateSettings = createActionAsync(
    'UPDATE_SETTINGS',
    (data, userId, sectionName, displayName, dispatch) => {
        const filteredSettings = Object.fromEntries(
            Object.entries(data.settings).filter(
                // Filtering out fields with asterisks
                (value: any[]) => (typeof value[1] === 'string' ? !/^\*+$/.test(value[1]) : true)
            )
        );
        return $api
            .post({
                url: '/settings',
                params: {
                    userId,
                    name: sectionName
                },
                data: {
                    ...data,
                    settings: filteredSettings
                }
            })
            .then((res) => {
                dispatch(setSettingsSection(data?.settings));
                dispatch(
                    enqueueSnackbar({
                        message: `Successfully saved ${displayName} settings`,
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return res.data;
            })
            .catch((error) => {
                if (!data?.muteSettingsErrors) {
                    dispatch(
                        enqueueSnackbar({
                            message: `Unsuccessfully saved ${displayName} settings`,
                            options: {
                                variant: 'error'
                            }
                        })
                    );
                }
                throw error;
            });
    }
);

export const editSettings = createActionAsync('EDIT_SETTINGS', (data, userId, sectionName, displayName, dispatch) =>
    $api
        .put({
            url: '/settings',
            params: {
                userId,
                name: sectionName
            },
            data
        })
        .then((res) => {
            dispatch(
                enqueueSnackbar({
                    message: `Successfully changed ${displayName} settings`,
                    options: {
                        variant: 'success'
                    }
                })
            );
            return res.data;
        })
        .catch((error) => {
            dispatch(
                enqueueSnackbar({
                    message: `Failed to change ${displayName} settings`,
                    options: {
                        variant: 'error'
                    }
                })
            );
            throw error;
        })
);

export const getSelectSearchData = createActionAsync('GET_SELECT_SEARCH_DATA', (value, fieldName) => {
    return $api
        .get({
            url: `/settings-metadata/${fieldName}/search/${value}`
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
});

export const setWebhooksMigrationId = createActionAsync('SET_WEBHOOKS_MIGRATION', (webhook) =>
    $api
        .post({
            url: 'pagerduty/webhooks/v2/migrate',
            data: webhook
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            throw error;
        })
);

export const getV2WebHooks = createActionAsync('GET_V2_WEBHOOKS', () =>
    $api
        .get({
            url: 'pagerduty/webhooks/v2'
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getV3WebHooks = createActionAsync('GET_V3_WEBHOOKS', () =>
    $api
        .get({
            url: 'pagerduty/webhooks/v3'
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const createWebhook = createActionAsync('CREATE_WEBHOOK', (version, payloadData, dispatch: Dispatch) =>
    $api
        .post({
            url: `pagerduty/webhooks/${version}`,
            data: payloadData
        })
        .then((res) => {
            dispatch(
                enqueueSnackbar({
                    message: `Successfully created ${payloadData.description}`,
                    options: {
                        variant: 'success'
                    }
                })
            );
            return res.data;
        })
        .catch((error) => {
            dispatch(
                enqueueSnackbar({
                    message: `Failed to create ${payloadData.description} webhook`,
                    options: {
                        variant: 'error'
                    }
                })
            );
            throw error;
        })
);

export const SettingsRPCMethod = createActionAsync('SETTINGS_RPC_METHOD', (rpcMethod, options) => {
    return $api
        .get({
            url: '/settings-metadata/get_options',
            params: {
                valueOptionsSource: rpcMethod,
                ...options
            }
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
});

export const GET_USER_TIMEZONE = 'GET_USER_TIMEZONE';
export const getUserTimezone = () => {
    return (dispatch) => {
        const dispatchObject: Record<string, any> = {
            type: GET_USER_TIMEZONE,
            payload: {},
            error: {},
            meta: {
                id: GET_USER_TIMEZONE,
                done: false
            }
        };
        if (!dispatch(dispatchObject)) {
            return;
        }
        return $api
            .get({
                url: '/timezone-setting'
            })
            .then((res) => {
                delete dispatchObject.error;
                dispatchObject.payload = res.data;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            })
            .catch((error) => {
                delete dispatchObject.payload;
                dispatchObject.error = error;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            });
    };
};

export const GET_INTEGRATION_PROBES = 'GET_INTEGRATION_PROBES';
export const getIntegrationProbes = () => {
    return (dispatch) => {
        const dispatchObject: Record<string, any> = {
            type: GET_INTEGRATION_PROBES,
            payload: {},
            error: {},
            meta: {
                id: GET_INTEGRATION_PROBES,
                done: false
            }
        };
        if (!dispatch(dispatchObject)) {
            return;
        }

        return $api
            .get({
                url: '/integration/probes'
            })
            .then((res) => {
                delete dispatchObject.error;
                dispatchObject.payload = res.data;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            })
            .catch((error) => {
                delete dispatchObject.payload;
                dispatchObject.error = error;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            });
    };
};

export const getTagCategories = createActionAsync(
    'GET_TAG_CATEGORIES',
    (includeArchived = true, errorMessage: string) =>
        $api
            .get({
                url: `/tagging/get_categories/?include_archived=${includeArchived}`
            })
            .then((res) => res.data)
            .catch((error) => {
                enqueueSnackbar({
                    message: errorMessage,
                    options: {
                        variant: 'error'
                    }
                });
                throw error;
            })
);

export const setTagCategoryFilterText = createAction<{ tagCategoryFilterText: string }>('SET_TAG_CATEGORY_FILTER_TEXT');
export const setTagCategorySortBy = createAction<{ tagCategorySortBy: string }>('SET_TAG_CATEGORY_SORT_BY');
export const setTagCategorySortDirection = createAction<{ tagCategorySortDirection: string }>(
    'SET_TAG_CATEGORY_SORT_DIRECTION'
);
export const setTagCategoryStatusFilter = createAction<{ tagCategoryStatusFilter: string }>(
    'SET_TAG_CATEGORY_STATUS_FILTER'
);
export const addExpandedTagCategory = createAction<{ id: number }>('ADD_EXPANDED_TAG_CATEGORY');
export const removeExpandedTagCategory = createAction<{ id: number }>('REMOVE_EXPANDED_TAG_CATEGORY');

export const addTagCategory = createActionAsync('ADD_TAG_CATEGORY', (category_name, description, errorMessage) =>
    $api
        .post({
            url: '/tagging/add_category/',
            data: { category_name, description }
        })
        .then((res) => res.data)
        .catch((error) => {
            // todo these don't seem to be showing anything when endpoints return 405...?
            enqueueSnackbar({
                message: errorMessage,
                options: {
                    variant: 'error'
                }
            });
            throw error;
        })
);

export const deleteTagCategory = createActionAsync('DELETE_CATEGORY', (category_id, errorMessage) =>
    $api
        .delete({
            url: `/tagging/delete_category/${category_id}`
        })
        .then((res) => res.data)
        .catch((error) => {
            enqueueSnackbar({
                message: errorMessage,
                options: {
                    variant: 'error'
                }
            });
            throw error;
        })
);

export const restoreTagCategory = createActionAsync('RESTORE_CATEGORY', (category_id, errorMessage) =>
    $api
        .put({
            url: `/tagging/unarchive_category/${category_id}`
        })
        .then((res) => res.data)
        .catch((error) => {
            enqueueSnackbar({
                message: errorMessage,
                options: {
                    variant: 'error'
                }
            });
            throw error;
        })
);

export const duplicateTagCategory = createActionAsync(
    'DUPLICATE_CATEGORY',
    (category_id, new_category_name, errorMessage) =>
        $api
            .post({
                url: '/tagging/duplicate_category/',
                data: { category_id, new_category_name }
            })
            .then((res) => res.data)
            .catch((error) => {
                enqueueSnackbar({
                    message: errorMessage,
                    options: {
                        variant: 'error'
                    }
                });
                throw error;
            })
);

export const updateTagCategory = createActionAsync(
    'UPDATE_TAG_CATEGORY',
    (
        id,
        name,
        description,
        fieldType,
        requiredForResolution,
        requiredForIncidentCreationModal,
        errorMessage,
        dispatch
    ) =>
        $api
            .put({
                url: '/tagging/update_category/',
                data: {
                    category_id: id,
                    category_name: name,
                    description,
                    field_type: fieldType,
                    required_for_resolution: requiredForResolution,
                    required_for_incident_creation_modal: requiredForIncidentCreationModal
                }
            })
            .then((res) => {
                dispatch(
                    enqueueSnackbar({
                        message: 'Tag category updated.',
                        options: {
                            variant: 'success'
                        }
                    })
                );
                res.data;
            })
            .catch((error) => {
                dispatch(
                    enqueueSnackbar({
                        message: errorMessage,
                        options: {
                            variant: 'error'
                        }
                    })
                );
                throw error;
            })
);

export const mergeTagCategories = createActionAsync(
    'MERGE_TAG_CATEGORY',
    (new_category_name, required_for_resolution, field_type, category_ids, errorMessage) =>
        $api
            .post({
                url: '/tagging/merge_tag_categories',
                data: {
                    new_category_name,
                    required_for_resolution,
                    field_type,
                    category_ids,
                    new_tag_category_description: '',
                    archive_merged_categories: true
                }
            })
            .then((res) => res.data)
            .catch((error) => {
                enqueueSnackbar({
                    message: errorMessage,
                    options: {
                        variant: 'error'
                    }
                });
                throw error;
            })
);

export const addTagToCategory = createActionAsync('ADD_TAG_TO_CATEGORY', (id, name, errorMessage) =>
    $api
        .put({
            url: '/tagging/add_tag_to_category/',
            data: {
                category_id: id,
                tag_label: name
            }
        })
        .then((res) => res.data)
        .catch((error) => {
            enqueueSnackbar({
                message: errorMessage,
                options: {
                    variant: 'error'
                }
            });
            throw error;
        })
);

export const removeTagFromCategory = createActionAsync('REMOVE_TAG_FROM_CATEGORY', (id, tagId, errorMessage) =>
    $api
        .delete({
            url: '/tagging/remove_tag_from_category/',
            data: {
                category_id: id,
                tag_id: tagId
            }
        })
        .then((res) => res.data)
        .catch((error) => {
            enqueueSnackbar({
                message: errorMessage,
                options: {
                    variant: 'error'
                }
            });
            throw error;
        })
);

export const addIncidentTypeToCategory = createActionAsync('ADD_INCIDENT_TYPE_TO_CATEGORY', (id, type, errorMessage) =>
    $api
        .put({
            url: '/tagging/add_incident_type_to_category/',
            data: {
                category_id: id,
                incident_type: type.label
            }
        })
        .then((res) => res.data)
        .catch((error) => {
            enqueueSnackbar({
                message: errorMessage,
                options: {
                    variant: 'error'
                }
            });
            throw error;
        })
);

export const removeIncidentTypeFromCategory = createActionAsync(
    'REMOVE_INCIDENT_TYPE_FROM_CATEGORY',
    (id, type, errorMessage) =>
        $api
            .delete({
                url: '/tagging/remove_incident_type_from_category/',
                data: {
                    category_id: id,
                    incident_type: type
                }
            })
            .then((res) => res.data)
            .catch((error) => {
                enqueueSnackbar({
                    message: errorMessage,
                    options: {
                        variant: 'error'
                    }
                });
                throw error;
            })
);

export const clearSettingsSection = createAction('CLEAR_SETTINGS_SECTION');
export const setSettingsSection = createAction<{ [key: string]: any }>('SET_SETTINGS_SECTION');
export const setIsOnboarding = createAction<{ isOnboarding: boolean }>('SET_IS_ONBOARDING');
export const setSchemaFormInvalid = createAction<boolean>('SET_SCHEMA_FORM_INVALID');
export const clearGoToMeetingOAuthUrl = createAction('CLEAR_GOTOMEETING_OAUTH_URL');

export const importPDServices = createActionAsync('IMPORT_PD_SERVICES', (onComplete, onError) =>
    $api
        .get({ url: '/pagerduty/services/import' })
        .then(({ data }) => {
            onComplete(data);
            return data;
        })
        .catch((error) => {
            onError(error);
        })
);

export const setIncidentTypeFilterText = createAction<{ incidentTypeFilterText: string }>(
    'SET_INCIDENT_TYPE_FILTER_TEXT'
);
export const setIncidentTypeSortBy = createAction<{ incidentTypeSortBy: string }>('SET_INCIDENT_TYPE_SORT_BY');
export const setIncidentTypeSortDirection = createAction<{ incidentTypeSortDirection: string }>(
    'SET_INCIDENT_TYPE_SORT_DIRECTION'
);
export const setIncidentTypeStatusFilter = createAction<{ incidentTypeStatusFilter: string }>(
    'SET_INCIDENT_TYPE_STATUS_FILTER'
);
