import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { NavbarSlideOutModal, HealthCheckNotification } from '@blamelesshq/blameless-components';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { RootState } from 'src/store';

const useStyles = makeStyles(() =>
    createStyles({
        navbarModal: {
            zIndex: '1000 !important' as unknown as 1000
        },
        noNotifications: {
            color: 'rgba(255, 255, 255, 0.55)',
            display: 'flex',
            flex: '1 1 0px',
            flexGrow: 1,
            fontStyle: 'italic',
            justifyContent: 'center'
        },
        slideOut: {
            left: '56px',
            zIndex: 1000
        }
    })
);

const messages = defineMessages({
    notifications: {
        id: 'sidenavigation.notifications.label',
        defaultMessage: 'Notifications',
        description: 'The label for the notification tab in the slide out modal'
    },
    healthCheck: {
        id: 'sidenavigationn.health.check.label',
        defaultMessage: 'Health Check',
        description: 'The label for the health check tab in the slide out modal'
    },
    noNotifications: {
        id: 'sidenavigation.notifications.none.label',
        defaultMessage: 'No Notifications...',
        description: 'The label for no notifications'
    },
    noHealthChecks: {
        id: 'sidenavigation.health.check.none.label',
        defaultMessage: 'No Health Checks Reported...',
        description: 'The label for no health checks'
    }
});

interface IProps {
    open: boolean;
    onClose: () => void;
}

const NotificationSlideOutModal = memo<IProps>(({ open, onClose }) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const healthProbes = useSelector((state: RootState) => state.settings.integrationProbes);
    const noNotifications = (
        <p className={classes.noNotifications} data-cy="navbar-no-notifications">
            {formatMessage(messages.noNotifications)}
        </p>
    );
    const noHealthChecks = (
        <p className={classes.noNotifications} data-cy="navbar-no-health-checks">
            {formatMessage(messages.noHealthChecks)}
        </p>
    );

    const tabs = [
        {
            tabBodyElement: noNotifications,
            tabNameElement: formatMessage(messages.notifications),
            tabValue: 'notifications'
        },
        {
            tabBodyElement:
                healthProbes.size > 0 ? <HealthCheckNotification integrationStates={healthProbes} /> : noHealthChecks,
            tabNameElement: formatMessage(messages.healthCheck),
            tabValue: 'health-check'
        }
    ];

    return (
        <>
            <NavbarSlideOutModal
                open={open}
                overrideClasses={{
                    modal: classes.navbarModal,
                    paperAnchorLeft: classes.slideOut
                }}
                headerElement={formatMessage(messages.notifications)}
                onClose={onClose}
                tabSections={tabs}
            />
        </>
    );
});

export default NotificationSlideOutModal;
