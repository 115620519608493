import $api from 'src/utils/api-interface';
import { $api_identity_v2 } from 'src/utils/apis/v2';
import { createActionAsync } from 'redux-act-async';
import { enqueueSnackbar } from 'src/actions/notification/notification-actions';

export const getOrgs = createActionAsync('GET_ORGS', () =>
    $api
        .get({ url: '/identity/org' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getGroups = createActionAsync('GET_GROUPS', (id) =>
    $api
        .get({ url: `/identity/group/org/${id}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const addGroup = createActionAsync('ADD_GROUP', (data) =>
    $api
        .post({ url: '/identity/group', data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const setGroupAsDefault = createActionAsync('SET_GROUP_AS_DEFAULT', (orgId, data, dispatch) =>
    $api
        .put({ url: `/identity/org/settings/${orgId}`, data })
        .then((res) => {
            dispatch(
                enqueueSnackbar({
                    message: 'Group set as default successfully',
                    options: {
                        variant: 'success'
                    }
                })
            );
            return res.data;
        })
        .catch((error) => {
            dispatch(
                enqueueSnackbar({
                    message: 'Error setting group as default',
                    options: {
                        variant: 'error'
                    }
                })
            );
            throw error;
        })
);

export const editGroup = createActionAsync('EDIT_GROUP', (id, data) =>
    $api
        .post({ url: `/identity/group/id/${id}`, data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const removeGroup = createActionAsync('REMOVE_GROUP', (id) =>
    $api
        .delete({ url: `/identity/group/id/${id}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getUsersIncludingDeleted = createActionAsync('GET_USERS_INCLUDING_DELETED', () =>
    $api
        .get({
            url: '/identity/user',
            params: { offset: 0, limit: 10000, include_deleted: true }
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getUsers = createActionAsync('GET_USERS', () =>
    $api
        .get({
            url: '/identity/user',
            params: { offset: 0, limit: 10000 }
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getUser = createActionAsync('GET_USER', (userId: number) =>
    $api
        .get({ url: `/identity/user/id/${userId}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const assignUsers = createActionAsync('ASSIGN_USERS', (data) =>
    $api
        .post({ url: '/identity/group/user', data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const deleteUserFromGroup = createActionAsync('DELETE_USER_FROM_GROUP', (orgId, id) =>
    $api
        .delete({ url: `/identity/group/user/${orgId}/${id}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const deleteUser = createActionAsync('DELETE_USER', (id, dispatch) =>
    $api
        .delete({ url: `/identity/user/id/${id}` })
        .then((res) => {
            dispatch(
                enqueueSnackbar({
                    message: `Successfully deleted userId: ${id}`,
                    options: {
                        variant: 'success'
                    }
                })
            );
            return res.data;
        })
        .catch((error) => {
            dispatch(
                enqueueSnackbar({
                    message: `Unsuccessfully deleted userId: ${id}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
            throw error;
        })
);

export const editUser = createActionAsync('EDIT_USER', (id, data, dispatch) =>
    $api
        .post({ url: `/identity/user/id/${id}`, data })
        .then((res) => {
            dispatch(
                enqueueSnackbar({
                    message: `Successfully edited userId: ${id}`,
                    options: {
                        variant: 'success'
                    }
                })
            );
            return res.data;
        })
        .catch((error) => {
            dispatch(
                enqueueSnackbar({
                    message: `Unsuccessfully edited userId: ${id}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
            throw error;
        })
);

export const getRoles = createActionAsync('GET_ROLES', () =>
    $api
        .get({ url: '/identity/role' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const assignRoles = createActionAsync('ASSIGN_ROLES', (data) =>
    $api
        .post({ url: '/identity/group/role', data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const addRoleCustom = createActionAsync('ADD_ROLE_CUSTOM', (data) =>
    $api
        .post({ url: '/identity/role/custom', data: { ...data, roles: ['admin', 'reader', 'writer'] } })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getGroupUsers = createActionAsync('GET_GROUP_USERS', (id) =>
    $api
        .get({ url: `/identity/group/users/${id}?limit=-1` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getGroupRoles = createActionAsync('GET_GROUP_ROLES', (id) =>
    $api
        .get({ url: `/identity/group/roles/${id}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getUserGroups = createActionAsync('GET_USER_GROUPS', (id) =>
    $api
        .get({ url: `/identity/user/groups/${id}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getUserRoles = createActionAsync('GET_USER_ROLES', () =>
    $api
        .get({ url: '/identity/user/roles' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getUserIntegrations = createActionAsync('GET_USER_INTEGRATIONS', () =>
    $api
        .get({
            url: '/identity/user/integrations'
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getRoleGroups = createActionAsync('GET_ROLE_GROUPS', (id) =>
    $api
        .get({ url: `/identity/role/groups/${id}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getRoleUsers = createActionAsync('GET_ROLE_USERS', (id) =>
    $api
        .get({ url: `/identity/role/users/${id}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getIntegrations = createActionAsync('GET_INTEGRATIONS', () =>
    $api
        .get({ url: '/identity/userintegration/type' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const saveOnboarding = createActionAsync('POST_ONBOARDING', (id, data, dispatch) =>
    $api
        .put({ url: `/identity/org/settings/${id}`, data })
        .then((res) => res.data)
        .catch((error) => {
            dispatch(
                enqueueSnackbar({
                    message: `Unsuccessfully updated onboarding for Org: ${id}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
            throw error;
        })
);

export const getUsersByIds = createActionAsync('GET_USERS_BY_ID', (orgId: number, userIds: number[]) =>
    $api
        .post({
            url: '/topics/identity/execute',
            data: {
                items: [
                    {
                        formula: '[identity_user.org_id]',
                        header: 'orgId',
                        filters: [
                            {
                                operator: 'eq',
                                values: [orgId]
                            }
                        ]
                    },
                    {
                        formula: '[identity_user.id]',
                        header: 'userId',
                        filters: [
                            {
                                operator: 'in',
                                values: userIds
                            }
                        ]
                    },
                    {
                        formula: '[identity_user.name]',
                        header: 'userName'
                    },
                    {
                        formula: '[identity_user.email]',
                        header: 'userEmail'
                    },
                    {
                        formula: '[identity_user.avatar_32]',
                        header: 'avatar32'
                    },
                    {
                        formula: '[identity_user.avatar_48]',
                        header: 'avatar48'
                    }
                ]
            }
        })
        .then((res) => res.data.result.rows)
        .catch((error) => {
            throw error;
        })
);

export const getTenantId = createActionAsync('GET_TENANT_ID', () =>
    $api
        .get({ url: '/identity/tenant' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getEmailDomains = createActionAsync('GET_EMAIL_DOMAINS', (): IAM.IEmailDomain[] =>
    $api_identity_v2
        .get({ url: '/email-domains' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updateEmailDomain = createActionAsync(
    'UPDATE_EMAIL_DOMAIN',
    (emailDomain: IAM.IEmailDomain): IAM.IEmailDomain =>
        $api_identity_v2
            .patch({ url: `/email-domains/${emailDomain.id}`, data: { active: !emailDomain.active } })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

export const addEmailDomain = createActionAsync(
    'ADD_EMAIL_DOMAIN',
    (domain: string): IAM.IEmailDomain =>
        $api_identity_v2
            .post({ url: '/email-domains', data: { domain } })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);
