import React, { useState, useEffect, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { Link, NavLink, NavLinkProps } from 'react-router-dom';

// Components
import Auth from 'src/auth/auth';
import { HelpIcon, NavBar, SearchIcon } from '@blamelesshq/blameless-components';
import { createStyles, makeStyles, Theme, useTheme, List, ListItem, ListItemIcon, Tooltip } from '@material-ui/core';
import UserNav from 'src/components/navigation/user-nav';
import getSections from 'src/components/navigation/utils/sections-utils';
import withSentryErrorBoundary from 'src/components/hocs/sentryErrorBoundary';
import NotificationIndicator from 'src/components/navigation/notification-indicator';
import NotificationSlideOutModal from 'src/components/navigation/notification-slide-out-modal';
import UserPermissions from 'src/components/auth/user-permissions';
import { useAuditLog } from 'src/components/hooks/use-audit-log';
import Portal from 'src/components/ui/portal';
import SlackSetupPopup from 'src/components/welcome/slack-setup-popup';
import { LoadStatus } from 'src/constants/load-status';
import SectionURLs from 'src/constants/urls';

// Constants
import { HELP_URL } from 'src/constants/urls';
import { MENU_SECTIONS_TYPES } from 'src/constants/common';
import { ROLE_COMPONENTS } from 'src/constants/user-permissions';
import { RESOURCE, ACTION } from 'src/constants/audit-log';

// Actions
import { toggleSearchSidebar } from 'src/actions/search/search-actions';
import { getIntegrationsStatus } from 'src/actions/integration/integration-actions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            color: theme.colors.icon.light
        },
        item: {
            '&:hover': {
                background: 'rgba(200, 185, 254, 0.30)'
            },
            height: '48px'
        },
        link: {
            textDecoration: 'none'
        },
        mainNav: {
            height: '100%'
        },
        menuItemActive: {
            '& svg': {
                color: theme.colors.ui.brand
            },
            background: 'rgba(200, 185, 254, 0.13)'
        },
        navBar: {
            zIndex: 1200
        },
        navIcon: {
            '& svg': {
                fontSize: '3.0rem',
                marginLeft: '-5px'
            }
        },
        menuTooltipArrow: {
            color: theme.colors.ui.grey1
        }
    })
);

const messages = defineMessages({
    helpLabel: {
        id: 'sidenavigation.menu.help.label',
        defaultMessage: 'Help',
        description: 'The label for the help icon'
    },
    searchLabel: {
        id: 'sidenavigation.menu.search.label',
        defaultMessage: 'Search',
        description: 'The label for the search icon'
    },
    notificationsLabel: {
        id: 'sidenavigation.menu.notifications.label',
        defaultMessage: 'Notifications',
        description: 'The label for the notifications icon'
    }
});

interface IProps {
    auth: Auth;
    activeItem: string;
}

const SideNavigation = ({ auth, activeItem }: IProps) => {
    const { isOnboarding } = useSelector((state: RootState) => state.settings);
    const { isSearchbarOpen } = useSelector((state: RootState) => state.search);
    const { slack: isSlackEnabled } = useSelector((state: RootState) => state.integration.integrationsStatus);
    const getIntegrationsStatusLoadStatus = useSelector(
        (state: RootState) => state.integration.getIntegrationsStatusLoadStatus
    );
    const { integrationProbes, standaloneSlo, enableSlackSetupReminderPopup, msTeamsPoc } = useSelector(
        (state: RootState) => state.launchDarkly
    );
    const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
    const wrappedSetIsNotificationModalOpen = () => setIsNotificationModalOpen(!isNotificationModalOpen);
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const auditLog = useAuditLog();

    const [isSlackPopupOpen, setIsSlackPopupOpen] = useState(false);
    const [slackPopupTimeoutId, setSlackPopupTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);

    // biome-ignore lint/nursery/useExhaustiveDependencies: <explanation>
    const refreshSlackPopupInterval = useCallback(
        (ms: number) => {
            if (slackPopupTimeoutId) {
                clearTimeout(slackPopupTimeoutId);
            }
            const timeoutId = setTimeout(() => {
                const pathname = window.location.pathname;
                if (
                    pathname === '/' ||
                    pathname === SectionURLs.WELCOME_PAGE_URL ||
                    pathname === SectionURLs.SLACK_AUTH_FLOW_URL
                ) {
                    setIsSlackPopupOpen(false);
                    refreshSlackPopupInterval(ms);
                    return;
                }
                setIsSlackPopupOpen(true);
            }, ms);
            setSlackPopupTimeoutId(timeoutId);
        },
        [slackPopupTimeoutId]
    );

    useEffect(() => {
        if (isSlackEnabled == null && getIntegrationsStatusLoadStatus === LoadStatus.EMPTY) {
            dispatch(getIntegrationsStatus());
        }
    }, [dispatch, isSlackEnabled, getIntegrationsStatusLoadStatus]);

    // biome-ignore lint/nursery/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        //5 seconds
        const ms = 1000 * 5;
        refreshSlackPopupInterval(ms);
    }, []);

    useEffect(
        () => () => {
            if (slackPopupTimeoutId) {
                clearTimeout(slackPopupTimeoutId);
            }
        },
        [slackPopupTimeoutId]
    );

    const auditLogNavClick = (action: string) => {
        auditLog.createAuditLog(action, RESOURCE.MAIN_NAV);
    };

    const handleHelpClick = (event: React.SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        auditLogNavClick(ACTION.MAIN_NAV_HELP);
        window.open(HELP_URL, '_blank');
    };

    const handleSlackPopupClose = () => {
        //10 minutes
        const ms = 1000 * 60 * 10;
        setIsSlackPopupOpen(false);
        refreshSlackPopupInterval(ms);
    };

    const menuSections: INavigation.INavigationSections = getSections({ activeItem });

    const getNavigationSections = (): INavigation.INavigationSection[] => {
        return menuSections[MENU_SECTIONS_TYPES.COMMON];
    };

    const navigationSections: INavigation.INavigationSection[] = getNavigationSections();

    const handleToggleSearchSidebar = () => {
        dispatch(toggleSearchSidebar());
    };

    if (isOnboarding) {
        return null;
    }

    const shouldSlackReminderPopupBeEnabled = enableSlackSetupReminderPopup && !msTeamsPoc && !isSlackEnabled;

    return (
        <>
            {integrationProbes && (
                <NotificationSlideOutModal open={isNotificationModalOpen} onClose={wrappedSetIsNotificationModalOpen} />
            )}
            <UserPermissions>
                {({ isLoading, userComponents }: IUserPermissions.IUserPermissionsProps) => (
                    <NavBar rootClass={classes.navBar} data-cy="side-navigation">
                        {!isLoading && (
                            <>
                                <List component="nav" className={classes.mainNav}>
                                    {navigationSections.map((section: INavigation.INavigationSection) => {
                                        if (!section.enabled) {
                                            return null;
                                        }
                                        return (
                                            <Tooltip
                                                data-cy="side-navigation-li-tooltip"
                                                key={section.key}
                                                title={section.name ?? ''}
                                                placement="right"
                                                arrow
                                                classes={{ arrow: classes.menuTooltipArrow }}
                                            >
                                                <ListItem
                                                    data-cy="side-navigation-li"
                                                    button
                                                    classes={{ root: classes.item }}
                                                    component={React.forwardRef<
                                                        HTMLAnchorElement,
                                                        Omit<NavLinkProps, 'innerRef' | 'to'>
                                                    >((props, ref) => (
                                                        <NavLink
                                                            innerRef={ref}
                                                            to={section.route}
                                                            activeClassName={classes.menuItemActive}
                                                            {...props}
                                                        />
                                                    ))}
                                                >
                                                    <ListItemIcon
                                                        classes={{ root: classes.navIcon }}
                                                        data-cy="side-navegation-li-icon"
                                                    >
                                                        {section.icon!}
                                                    </ListItemIcon>
                                                </ListItem>
                                            </Tooltip>
                                        );
                                    })}
                                    {!standaloneSlo &&
                                        (userComponents[ROLE_COMPONENTS.ALL] ||
                                            userComponents[ROLE_COMPONENTS.INCIDENT]) && (
                                            <Tooltip
                                                title={formatMessage(messages.searchLabel)}
                                                placement="right"
                                                arrow
                                                classes={{ arrow: classes.menuTooltipArrow }}
                                            >
                                                <ListItem
                                                    button
                                                    classes={{ root: classes.item }}
                                                    onClick={() => {
                                                        auditLogNavClick(ACTION.MAIN_NAV_SEARCH);
                                                        handleToggleSearchSidebar();
                                                    }}
                                                >
                                                    <ListItemIcon classes={{ root: classes.navIcon }}>
                                                        <SearchIcon
                                                            fillColor={
                                                                isSearchbarOpen
                                                                    ? theme.colors.ui.brand
                                                                    : theme.colors.ui.white
                                                            }
                                                        />
                                                    </ListItemIcon>
                                                </ListItem>
                                            </Tooltip>
                                        )}
                                </List>
                            </>
                        )}

                        <List component="nav">
                            {integrationProbes && (
                                <Tooltip
                                    title={formatMessage(messages.notificationsLabel)}
                                    placement="right"
                                    arrow
                                    classes={{ arrow: classes.menuTooltipArrow }}
                                >
                                    <ListItem
                                        button
                                        classes={{ root: classes.item }}
                                        onClick={wrappedSetIsNotificationModalOpen}
                                    >
                                        <ListItemIcon classes={{ root: classes.navIcon }}>
                                            <NotificationIndicator onClick={wrappedSetIsNotificationModalOpen} />
                                        </ListItemIcon>
                                    </ListItem>
                                </Tooltip>
                            )}
                            <Tooltip
                                title={formatMessage(messages.helpLabel)}
                                placement="right"
                                arrow
                                classes={{ arrow: classes.menuTooltipArrow }}
                            >
                                <ListItem
                                    button
                                    classes={{ root: classes.item }}
                                    component={React.forwardRef<
                                        HTMLAnchorElement,
                                        Omit<NavLinkProps, 'innerRef' | 'to'>
                                    >((props, ref) => (
                                        <Link
                                            innerRef={ref}
                                            className={classes.menuItemActive}
                                            {...props}
                                            to=""
                                            onClick={handleHelpClick}
                                        />
                                    ))}
                                >
                                    <ListItemIcon classes={{ root: classes.navIcon }}>
                                        <HelpIcon fillColor={theme.colors.icon.light} />
                                    </ListItemIcon>
                                </ListItem>
                            </Tooltip>
                            <ListItem classes={{ root: classes.item }} disableGutters dense>
                                <UserNav auth={auth} onClick={() => auditLogNavClick(ACTION.MAIN_NAV_USER)} />
                            </ListItem>
                        </List>
                    </NavBar>
                )}
            </UserPermissions>
            {isSlackPopupOpen && shouldSlackReminderPopupBeEnabled && (
                <Portal parentId="root">
                    <SlackSetupPopup onClose={handleSlackPopupClose} />
                </Portal>
            )}
        </>
    );
};

export default withSentryErrorBoundary(SideNavigation);
