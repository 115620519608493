export const PAGE_SIZE = 50;
export const SLO_PAGE_SIZE = 100;
export const TASK_PAGE_SIZE = 100;

export const SEVERITY0_COLOR = '#DF5A49';
export const SEVERITY1_COLOR = '#E27A3F';
export const SEVERITY2_COLOR = '#EFC94C';
export const SEVERITY3_COLOR = '#45B29D';
export const SEVERITY4_COLOR = '#334D5C';

export const INVESTIGATING_COLOR = '#f14e40';
export const IDENTIFIED_COLOR = '#daa038';
export const MONITORING_COLOR = '#306EFF';
export const RESOLVED_COLOR = '#2eb886';
export const DUPLICATE_COLOR = '#50514f';

// RECHARTS STYLES CONSTANTS
export const TICK_FONT_SIZE = '9px';
export const STROKE_COLOR = 'rgba(0, 0, 0, 0.5)';
export const STROKE_WIDTH = '1px';
export const IMPORTANT_STROKE_COLOR = 'rgba(0, 0, 0, 1)';
export const IMPORTANT_STROKE_WIDTH = '2px';

//MENU SECTIONS TYPES
export const MENU_SECTIONS_TYPES = {
    COMMON: 'COMMON',
    STANDALONE_SLO: 'STANDALONE_SLO'
};

// PAGES TITLES
export const PAGES_TITLES = {
    USER_JOURNEYS_TITLE: 'User Journeys',
    USER_JOURNEY_TITLE: 'User Journey'
};

export const PENDO_SALT = '-)4,1)%Q';

export const COMMON_DEBOUNCE_TIME = 350;

export const UUID_LENGTH = 36;

export const EMAIL_DOMAIN_REGEX = /^([a-zA-Z0-9]+([a-zA-Z0-9-]+[a-zA-Z0-9]+)?\.)+[a-zA-Z]{2,}$/i;
