import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { defineMessages, useIntl } from 'react-intl';

// Components
import {
    makeStyles,
    Theme,
    ListItem,
    IconButton,
    Menu,
    MenuItem,
    ListItemText,
    Typography,
    createStyles
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import { EditIcon, ImageIcon, SectionNav } from '@blamelesshq/blameless-components';
import AddBlankBoardModal from 'src/components/dashboard/dashboard-pages/modals/add-blank-board-modal';
import CloneBoardModal from 'src/components/dashboard/dashboard-pages/modals/clone-board-modal';

// Constants
import { NEW_BOARD_TYPES } from 'src/constants/dashboard';
import { LoadStatus } from 'src/constants/load-status';

// Utils
import { trackEvent } from 'src/utils/tracking-utils';
import { EVENTS } from 'src/constants/track-events';
import { usePermissionGuard } from '../auth/permission-guard';

const messages = defineMessages({
    reliabilityInsights: {
        id: 'dashboard.navigation.reliabilityInsights',
        defaultMessage: 'Reliability Insights',
        description: 'Reliability Insights navigation title'
    },
    customBoards: {
        id: 'dashboard.navigation.customBoards',
        defaultMessage: 'Custom Boards',
        description: 'Reliability Insights custom boards title'
    },
    blamelessBoards: {
        id: 'dashboard.navigation.blamelessBoards',
        defaultMessage: 'Blameless Boards',
        description: 'Reliability Insights blameless boards title'
    },
    createScratch: {
        id: 'dashboard.navigation.addBoard.createScratch',
        defaultMessage: 'Create from scratch',
        description: 'Reliability Insights create boards from scratch menu'
    },
    createFromExisting: {
        id: 'dashboard.navigation.addBoard.createFromExisting',
        defaultMessage: 'Create from existing board',
        description: 'Reliability Insights create boards from existing ones menu'
    }
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        navLink: {
            paddingTop: '5px',
            paddingBottom: '5px',
            borderRadius: '4px',
            minHeight: '32px',
            '&:hover': {
                backgroundColor: '#0000000d'
            }
        },
        activeLink: {
            backgroundColor: '#0000000d'
        },
        menuIcon: {
            marginRight: '9px'
        },
        addIconButton: {
            width: '24px',
            height: '24px',
            backgroundColor: theme.colors.ui.primary,
            borderRadius: '4px',
            '& svg': {
                color: theme.colors.ui.white
            },
            '&:hover': {
                backgroundColor: theme.colors.ui.primary
            }
        },
        boardSection: {
            padding: '0 16px',
            '& > p': {
                fontWeight: 'bold',
                padding: '9px'
            },
            '& span, p': {
                textTransform: 'capitalize'
            }
        }
    })
);

const DashboardNavigation = () => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const [modal, setModal] = useState('');
    const boards = useSelector((state: RootState) => state.dashboard.boards);
    const boardsLoadStatus = useSelector((state: RootState) => state.dashboard.boardsLoadStatus);

    const { isAuthorized: isDashboardWriter } = usePermissionGuard({
        permissions: [{ component: 'DASHBOARD', action: 'CREATE' }]
    });

    const readonlyBoards = boards.filter((board) => board.isReadonly);
    const customBoards = boards.filter((board) => !board.isReadonly);

    const isLoading =
        (!boards.length && boardsLoadStatus === LoadStatus.REQUEST) || boardsLoadStatus === LoadStatus.EMPTY;
    const addBoardMenu = usePopupState({ variant: 'popover', popupId: 'addBoardMenu' });

    const handleAddBlankBoard = () => {
        addBoardMenu.close();
        setModal(NEW_BOARD_TYPES.BLANK);
        trackEvent(EVENTS.DASHBOARD_NEW_BOARD);
    };

    const handleAddFromExistingBoard = () => {
        addBoardMenu.close();
        setModal(NEW_BOARD_TYPES.CLONE_EXISTING);
        trackEvent(EVENTS.DASHBOARD_CLONE_BOARD);
    };

    return (
        <>
            <SectionNav
                navTitle={formatMessage(messages.reliabilityInsights)}
                navHeaderMenu={
                    <>
                        <IconButton
                            data-cy="add-board-button"
                            className={classes.addIconButton}
                            {...bindTrigger(addBoardMenu)}
                        >
                            <Add fontSize="small" />
                        </IconButton>
                        <Menu {...bindMenu(addBoardMenu)}>
                            <MenuItem
                                data-cy="create-blank-board-menu"
                                onClick={handleAddBlankBoard}
                                disabled={!isDashboardWriter}
                            >
                                <EditIcon className={classes.menuIcon} />
                                {formatMessage(messages.createScratch)}
                            </MenuItem>
                            <MenuItem
                                data-cy="create-from-existing-board-menu"
                                onClick={handleAddFromExistingBoard}
                                disabled={!isDashboardWriter}
                            >
                                <ImageIcon className={classes.menuIcon} />
                                {formatMessage(messages.createFromExisting)}
                            </MenuItem>
                        </Menu>
                    </>
                }
                navContent={
                    <>
                        {!!readonlyBoards.length && (
                            <div className={classes.boardSection}>
                                <Typography>{formatMessage(messages.blamelessBoards)}</Typography>
                                {readonlyBoards.map((board) => (
                                    <ListItem
                                        button
                                        data-cy="blameless-board"
                                        key={board._id}
                                        className={classes.navLink}
                                        component={NavLink}
                                        exact
                                        to={`/reliability-insights/${board._id}`}
                                        activeClassName={classes.activeLink}
                                    >
                                        <ListItemText primary={board.name} />
                                    </ListItem>
                                ))}
                            </div>
                        )}
                        {!!customBoards.length && (
                            <div className={classes.boardSection}>
                                <Typography>{formatMessage(messages.customBoards)}</Typography>
                                {customBoards.map((board) => (
                                    <ListItem
                                        button
                                        data-cy="custom-board"
                                        key={board._id}
                                        className={classes.navLink}
                                        component={NavLink}
                                        exact
                                        to={`/reliability-insights/${board._id}`}
                                        activeClassName={classes.activeLink}
                                    >
                                        <ListItemText primary={board.name} />
                                    </ListItem>
                                ))}
                            </div>
                        )}
                    </>
                }
                isLoading={isLoading}
            />
            <AddBlankBoardModal isOpen={modal === NEW_BOARD_TYPES.BLANK} onClose={() => setModal('')} />
            <CloneBoardModal modal={modal} onClose={() => setModal('')} />
        </>
    );
};

export default DashboardNavigation;
