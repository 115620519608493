import React, { Suspense, lazy, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { RootState } from 'src/store';
import { getBoards, getFacts, getSegments, getTopics } from 'src/actions/dashboard/dashboard-page-actions';
import LoadingPageIndicator from 'src/components/ui/loading-page-indicator/loading-page-indicator';
import DashboardNavigation from 'src/components/dashboard/dashboard-navigation';
import LoadStatus from 'src/constants/load-status';
const ErrorNotFound = lazy(() => import('src/components/ui/error-not-found/error-not-found'));

const DashboardPage = lazy(() => import('src/components/dashboard/dashboard-pages/dashboard-page'));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootWrapper: {
            display: 'flex',
            margin: '-10px -15px',
            minHeight: '100vh',
            overflow: 'hidden',
            backgroundColor: theme.colors.ui.grey7
        },
        dashboardNav: {
            minWidth: '224px'
        }
    })
);

const DashboardRoutes = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const boards = useSelector((state: RootState) => state.dashboard.boards);
    const boardsLoadStatus = useSelector((state: RootState) => state.dashboard.boardsLoadStatus);

    useEffect(() => {
        async function fetchData() {
            dispatch(
                getBoards(true, {
                    filter: 'rw',
                    value: ['3', 'month', '0', 'day']
                })
            );

            const data = await dispatch(getTopics());

            data.response.topics.forEach((topic) => dispatch(getSegments(topic.name)));

            dispatch(getFacts());
        }
        if (boardsLoadStatus === LoadStatus.EMPTY) {
            fetchData();
        }
    }, [dispatch, boardsLoadStatus]);

    return (
        <div className={classes.rootWrapper}>
            <DashboardNavigation />

            <Suspense fallback={<LoadingPageIndicator />}>
                <Switch>
                    <Route path="/reliability-insights/:boardId" exact component={DashboardPage} />
                    {boards && boards.length > 0 && (
                        <Redirect exact from="/reliability-insights" to={`/reliability-insights/${boards[0]._id}`} />
                    )}
                    <Route path="/reliability-insights" exact component={DashboardPage} />

                    <Route component={ErrorNotFound} />
                </Switch>
            </Suspense>
        </div>
    );
};

export default DashboardRoutes;
