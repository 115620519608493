import produce from 'immer';
import { LoadStatus } from 'src/constants/load-status';
import {
    REQUIRED_FIELDS_TYPES,
    REQUIRED_FIELD_TYPE_INCIDENT,
    REQUIRED_FIELD_TYPE_FOLLOWUP
} from 'src/constants/schema-fields';
import {
    GET_JIRA_PROJECTS_RPC,
    GET_JIRA_FOLLOWUP_PROJECTS_RPC,
    GET_JIRA_FOLLOWUP_CREATE_PROJECTS_RPC,
    GET_JIRA_ISSUE_TYPES_RPC,
    GET_JIRA_FOLLOWUP_ISSUE_TYPES_RPC,
    GET_JIRA_FOLLOWUP_CREATE_ISSUE_TYPES_RPC,
    GET_JIRA_REQUIRED_FIELDS,
    setJiraProject,
    setJiraFollowupProject,
    setJiraFollowupCreateProject,
    setJiraIssueType,
    setJiraIssueTypes,
    setJiraRequiredFields,
    setJiraFollowUpIssueType,
    setJiraFollowUpIssueTypes,
    setJiraFollowUpCreateIssueType,
    setJiraFollowUpCreateIssueTypes,
    clearJiraProject,
    clearJiraProjects,
    clearJiraIssueType,
    clearJiraIssueTypes,
    clearAllJiraProperties,
    clearJiraRequiredFields,
    clearJiraFollowUpIssueType,
    clearJiraFollowUpCreateIssueType,
    GET_PAGER_DUTY_SERVICES_RPC,
    getMorePagerDutyServices,
    GET_MS_TEAMS_GROUPS_RPC,
    GET_MS_TEAMS_CHANNELS_RPC,
    SET_MS_TEAMS_GROUP,
    SET_MS_TEAMS_CHANNEL,
    CLEAR_MS_TEAMS_CHANNEL_REQUIRED_FIELDS,
    CLEAR_MS_TEAMS_GROUP_REQUIRED_FIELDS,
    GET_MS_TEAMS_USERS_RPC,
    SET_MS_TEAMS_USER,
    CLEAR_MS_TEAMS_USER_REQUIRED_FIELDS,
    getJiraAssignees,
    GET_SERVICENOW_SERVICES_RPC,
    getMoreServiceNowServices,
    GET_OPSGENIE_SERVICES_RPC,
    getMoreOpsgenieServices
} from 'src/actions/json-schema/json-schema-actions';

export type JsonSchemaReducerAction = Readonly<{
    type: string;
    payload: any;
    meta: any;
    error: any;
}>;

interface IPagination {
    limit: number;
    next_offset: number;
    has_more: boolean;
}

interface JiraProject {
    value: string;
    label: string;
}

interface JiraAssignee {
    value: string;
    label: string;
}

export type JsonSchemaState = Readonly<{
    getJiraProjectsLoadStatus: string;
    getJiraFollowupProjectsLoadStatus: string;
    getJiraFollowupCreateProjectsLoadStatus: string;
    getJiraIssueTypesLoadStatus: string;
    getJiraFollowupIssueTypesLoadStatus: string;
    getJiraFollowupCreateIssueTypesLoadStatus: string;
    getJiraRequiredFieldsLoadStatus: string;
    jiraProjectId: string;
    jiraProjectDisplayValue: string;
    jiraProjectsTotal: number;
    jiraProjectsOffset: number;
    jiraProjects: JiraProject[];
    jiraFollowupProjectId: string;
    jiraFollowupProjectDisplayValue: string;
    jiraFollowupProjectsTotal: number;
    jiraFollowupProjectsOffset: number;
    jiraFollowupProjects: JiraProject[];
    jiraFollowupCreateProjectId: string;
    jiraFollowupCreateProjectDisplayValue: string;
    jiraFollowupCreateProjectsTotal: number;
    jiraFollowupCreateProjectsOffset: number;
    jiraFollowupCreateProjects: JiraProject[];
    msTeamsGroups: any[];
    msTeamsGroupsDisplayName: string;
    msTeamsGroupsId: string;
    msTeamsGroupsLoadStatus: string;
    msTeamsChannels: any[];
    msTeamsChannelsLoadStatus: string;
    selectedMsTeamsChannels: any[];
    msTeamsUsersLoadStatus: string;
    msTeamsUsers: any[];
    selectedMsTeamsUsers: any[];
    jiraIssueType: string;
    jiraIssueTypes: string[];
    jiraFollowUpIssueType: string;
    jiraFollowupIssueTypes: string[];
    jiraFollowUpCreateIssueType: string;
    jiraFollowupCreateIssueTypes: string[];
    jiraRequiredFields: Settings.ISetting[];
    getPagerDutyStatus: string;
    pagerDutyList: any[];
    pagerDutyPagination: IPagination;
    jiraAssignees: JiraAssignee[];
    getJiraAssigneesStatus: string;
    getServiceNowStatus: string;
    serviceNowList: any[];
    serviceNowPagination: IPagination;
    getOpsgenieStatus: string;
    opsgenieList: any[];
    opsgeniePagination: IPagination;
}>;

export const defaultState: JsonSchemaState = {
    getJiraProjectsLoadStatus: LoadStatus.EMPTY,
    getJiraFollowupProjectsLoadStatus: LoadStatus.EMPTY,
    getJiraFollowupCreateProjectsLoadStatus: LoadStatus.EMPTY,
    getJiraIssueTypesLoadStatus: LoadStatus.EMPTY,
    getJiraFollowupIssueTypesLoadStatus: LoadStatus.EMPTY,
    getJiraFollowupCreateIssueTypesLoadStatus: LoadStatus.EMPTY,
    getJiraRequiredFieldsLoadStatus: LoadStatus.EMPTY,
    jiraProjectId: '',
    jiraProjectDisplayValue: '',
    jiraProjectsOffset: 0,
    jiraProjectsTotal: Number.MAX_SAFE_INTEGER,
    jiraProjects: [],

    jiraFollowupProjectId: '',
    jiraFollowupProjectDisplayValue: '',
    jiraFollowupProjectsOffset: 0,
    jiraFollowupProjectsTotal: Number.MAX_SAFE_INTEGER,
    jiraFollowupProjects: [],

    jiraFollowupCreateProjectId: '',
    jiraFollowupCreateProjectDisplayValue: '',
    jiraFollowupCreateProjectsOffset: 0,
    jiraFollowupCreateProjectsTotal: Number.MAX_SAFE_INTEGER,
    jiraFollowupCreateProjects: [],

    msTeamsGroups: [],
    msTeamsGroupsDisplayName: '',
    msTeamsGroupsId: '',
    msTeamsGroupsLoadStatus: LoadStatus.EMPTY,
    msTeamsChannels: [],
    msTeamsChannelsLoadStatus: LoadStatus.EMPTY,
    selectedMsTeamsChannels: [],
    msTeamsUsers: [],
    selectedMsTeamsUsers: [],
    msTeamsUsersLoadStatus: LoadStatus.EMPTY,
    jiraIssueType: '',
    jiraIssueTypes: [],
    jiraFollowUpIssueType: '',
    jiraFollowupIssueTypes: [],
    jiraFollowUpCreateIssueType: '',
    jiraFollowupCreateIssueTypes: [],
    jiraRequiredFields: [],
    getPagerDutyStatus: LoadStatus.EMPTY,
    pagerDutyList: [],
    pagerDutyPagination: {} as IPagination,
    jiraAssignees: [],
    getJiraAssigneesStatus: LoadStatus.EMPTY,
    getServiceNowStatus: LoadStatus.EMPTY,
    serviceNowList: [],
    serviceNowPagination: {} as IPagination,

    getOpsgenieStatus: LoadStatus.EMPTY,
    opsgenieList: [],
    opsgeniePagination: {} as IPagination
};

export default function (state: JsonSchemaState = defaultState, action: JsonSchemaReducerAction) {
    return produce(state, (draft) => {
        switch (action.type) {
            case GET_MS_TEAMS_GROUPS_RPC:
                if (!action.meta.done) {
                    draft.msTeamsGroupsLoadStatus = LoadStatus.REQUEST;
                    return;
                }

                if (action.error && action.meta.done) {
                    draft.msTeamsGroupsLoadStatus = LoadStatus.ERROR;
                    return;
                }

                if (action.meta.done && !action.error) {
                    draft.msTeamsGroupsLoadStatus = LoadStatus.OK;
                    if (action.payload) {
                        draft.msTeamsGroups = action.payload;
                    }
                    return;
                }
                return;
            case SET_MS_TEAMS_GROUP:
                if (action.payload) {
                    draft.msTeamsGroupsDisplayName = action.payload.msTeamsGroupsDisplayName;
                    draft.msTeamsGroupsId = action.payload.msTeamsGroupsId;
                }
                return;
            case SET_MS_TEAMS_CHANNEL:
                if (action.payload) {
                    draft.selectedMsTeamsChannels = action.payload;
                }
                return;
            case CLEAR_MS_TEAMS_CHANNEL_REQUIRED_FIELDS:
                draft.msTeamsChannels = [];
                return;
            case SET_MS_TEAMS_USER:
                if (action.payload) {
                    draft.selectedMsTeamsUsers = action.payload;
                }
                return;
            case CLEAR_MS_TEAMS_USER_REQUIRED_FIELDS:
                draft.msTeamsUsers = [];
                return;
            case CLEAR_MS_TEAMS_GROUP_REQUIRED_FIELDS:
                draft.msTeamsGroups = [];
                return;
            case GET_MS_TEAMS_CHANNELS_RPC:
                if (!action.meta.done) {
                    draft.msTeamsChannelsLoadStatus = LoadStatus.REQUEST;
                    return;
                }

                if (action.error && action.meta.done) {
                    draft.msTeamsChannelsLoadStatus = LoadStatus.ERROR;
                    return;
                }

                if (action.meta.done && !action.error) {
                    draft.msTeamsChannelsLoadStatus = LoadStatus.OK;
                    if (action.payload) {
                        draft.msTeamsChannels = action.payload;
                    }
                    return;
                }
                return;
            case GET_MS_TEAMS_USERS_RPC:
                if (!action.meta.done) {
                    draft.msTeamsUsersLoadStatus = LoadStatus.REQUEST;
                    return;
                }

                if (action.error && action.meta.done) {
                    draft.msTeamsUsersLoadStatus = LoadStatus.ERROR;
                    return;
                }

                if (action.meta.done && !action.error) {
                    draft.msTeamsUsersLoadStatus = LoadStatus.OK;
                    if (action.payload) {
                        draft.msTeamsUsers = action.payload;
                    }
                    return;
                }
                return;
            case GET_JIRA_PROJECTS_RPC:
                if (!action.meta.done) {
                    draft.getJiraProjectsLoadStatus = LoadStatus.REQUEST;
                    return;
                }

                if (action.error && action.meta.done) {
                    draft.getJiraProjectsLoadStatus = LoadStatus.ERROR;
                    return;
                }

                if (action.meta.done && !action.error) {
                    draft.getJiraProjectsLoadStatus = LoadStatus.OK;
                    if (action.payload) {
                        draft.jiraProjects = action.payload.values;
                        draft.jiraProjectsTotal = parseInt(action.payload.total);
                        draft.jiraProjectsOffset = draft.jiraProjectsOffset + parseInt(action.payload.limit);
                    }
                    return;
                }
                return;
            case GET_JIRA_FOLLOWUP_PROJECTS_RPC:
                if (!action.meta.done) {
                    draft.getJiraFollowupProjectsLoadStatus = LoadStatus.REQUEST;
                    return;
                }

                if (action.error && action.meta.done) {
                    draft.getJiraFollowupProjectsLoadStatus = LoadStatus.ERROR;
                    return;
                }

                if (action.meta.done && !action.error) {
                    draft.getJiraFollowupProjectsLoadStatus = LoadStatus.OK;
                    if (action.payload) {
                        draft.jiraFollowupProjects = action.payload.values;
                        draft.jiraFollowupProjectsTotal = parseInt(action.payload.total);
                        draft.jiraFollowupProjectsOffset =
                            draft.jiraFollowupProjectsOffset + parseInt(action.payload.limit);
                    }
                    return;
                }
                return;
            case GET_JIRA_FOLLOWUP_CREATE_PROJECTS_RPC:
                if (!action.meta.done) {
                    draft.getJiraFollowupCreateProjectsLoadStatus = LoadStatus.REQUEST;
                    return;
                }

                if (action.error && action.meta.done) {
                    draft.getJiraFollowupCreateProjectsLoadStatus = LoadStatus.ERROR;
                    return;
                }

                if (action.meta.done && !action.error) {
                    draft.getJiraFollowupCreateProjectsLoadStatus = LoadStatus.OK;
                    if (action.payload) {
                        draft.jiraFollowupCreateProjects = action.payload.values;
                        draft.jiraFollowupCreateProjectsTotal = parseInt(action.payload.total);
                        draft.jiraFollowupCreateProjectsOffset =
                            draft.jiraFollowupCreateProjectsOffset + parseInt(action.payload.limit);
                    }
                    return;
                }
                return;
            case GET_JIRA_ISSUE_TYPES_RPC:
                if (!action.meta.done) {
                    draft.getJiraIssueTypesLoadStatus = LoadStatus.REQUEST;
                    return;
                }

                if (action.error && action.meta.done) {
                    draft.getJiraIssueTypesLoadStatus = LoadStatus.ERROR;
                    return;
                }

                if (action.meta.done && !action.error) {
                    draft.getJiraIssueTypesLoadStatus = LoadStatus.OK;
                    if (action.payload) {
                        draft.jiraIssueTypes = action.payload;
                    }
                    return;
                }
                return;
            case GET_JIRA_FOLLOWUP_ISSUE_TYPES_RPC:
                if (!action.meta.done) {
                    draft.getJiraFollowupIssueTypesLoadStatus = LoadStatus.REQUEST;
                    return;
                }

                if (action.error && action.meta.done) {
                    draft.getJiraFollowupIssueTypesLoadStatus = LoadStatus.ERROR;
                    return;
                }

                if (action.meta.done && !action.error) {
                    draft.getJiraFollowupIssueTypesLoadStatus = LoadStatus.OK;
                    if (action.payload) {
                        draft.jiraFollowupIssueTypes = action.payload;
                    }
                    return;
                }
                return;
            case GET_JIRA_FOLLOWUP_CREATE_ISSUE_TYPES_RPC:
                if (!action.meta.done) {
                    draft.getJiraFollowupCreateIssueTypesLoadStatus = LoadStatus.REQUEST;
                    return;
                }

                if (action.error && action.meta.done) {
                    draft.getJiraFollowupCreateIssueTypesLoadStatus = LoadStatus.ERROR;
                    return;
                }

                if (action.meta.done && !action.error) {
                    draft.getJiraFollowupCreateIssueTypesLoadStatus = LoadStatus.OK;
                    if (action.payload) {
                        draft.jiraFollowupCreateIssueTypes = action.payload;
                    }
                    return;
                }
                return;
            case GET_JIRA_REQUIRED_FIELDS:
                if (!action.meta.done) {
                    draft.getJiraRequiredFieldsLoadStatus = LoadStatus.REQUEST;
                    return;
                }

                if (action.error && action.meta.done) {
                    draft.getJiraRequiredFieldsLoadStatus = LoadStatus.ERROR;
                    return;
                }

                if (action.meta.done && !action.error) {
                    draft.getJiraRequiredFieldsLoadStatus = LoadStatus.OK;
                    if (action.payload) {
                        const { fields, type } = action.payload;
                        let newFields = fields;
                        if (type === REQUIRED_FIELD_TYPE_FOLLOWUP) {
                            newFields = fields
                                .map((field) => {
                                    return {
                                        ...field,
                                        name: field.name.replace(
                                            REQUIRED_FIELDS_TYPES[REQUIRED_FIELD_TYPE_INCIDENT],
                                            REQUIRED_FIELDS_TYPES[REQUIRED_FIELD_TYPE_FOLLOWUP]
                                        ),
                                        uiLabel: field.uiLabel.replace('Incident', 'Follow Up')
                                    };
                                })
                                .filter((field) => !field.name.includes(`${REQUIRED_FIELDS_TYPES.followup}.summary`));
                        }
                        draft.jiraRequiredFields = [...draft.jiraRequiredFields, ...newFields];
                    }
                    return;
                }
                return;
            case setJiraIssueType.getType():
                draft.jiraIssueType = action.payload.issueType;
                return;
            case setJiraIssueTypes.getType():
                draft.jiraIssueTypes = action.payload.issueTypes;
                return;
            case setJiraFollowUpIssueType.getType():
                draft.jiraFollowUpIssueType = action.payload.followUpIssueType;
                return;
            case setJiraFollowUpIssueTypes.getType():
                draft.jiraFollowupIssueTypes = action.payload.followUpIssueTypes;
                return;
            case setJiraFollowUpCreateIssueType.getType():
                draft.jiraFollowUpCreateIssueType = action.payload.followUpCreateIssueType;
                return;
            case setJiraFollowUpCreateIssueTypes.getType():
                draft.jiraFollowupCreateIssueTypes = action.payload.followUpCreateIssueTypes;
                return;
            case clearJiraProject.getType():
                draft.jiraProjectId = '';
                draft.jiraProjectDisplayValue = '';
                return;
            case clearJiraProjects.getType():
                draft.jiraProjects = [];
                return;
            case clearJiraIssueType.getType():
                draft.jiraIssueType = '';
                return;
            case clearJiraIssueTypes.getType():
                draft.jiraIssueTypes = [];
                return;
            case clearJiraRequiredFields.getType():
                const { type } = action.payload;
                draft.jiraRequiredFields = draft.jiraRequiredFields.filter(
                    (field) => !field.name.includes(REQUIRED_FIELDS_TYPES[type])
                );
                return;
            case clearJiraFollowUpIssueType.getType():
                draft.jiraFollowUpIssueType = '';
                return;
            case clearJiraFollowUpCreateIssueType.getType():
                draft.jiraFollowUpCreateIssueType = '';
                return;
            case clearAllJiraProperties.getType():
                draft.getJiraProjectsLoadStatus = LoadStatus.EMPTY;
                draft.getJiraFollowupProjectsLoadStatus = LoadStatus.EMPTY;
                draft.getJiraFollowupCreateProjectsLoadStatus = LoadStatus.EMPTY;
                draft.getJiraIssueTypesLoadStatus = LoadStatus.EMPTY;
                draft.getJiraRequiredFieldsLoadStatus = LoadStatus.EMPTY;
                draft.jiraProjectId = '';
                draft.jiraProjectDisplayValue = '';
                draft.jiraProjectsOffset = 0;
                draft.jiraProjectsTotal = Number.MAX_SAFE_INTEGER;
                draft.jiraProjects = [];
                draft.jiraFollowupProjectId = '';
                draft.jiraFollowupProjectDisplayValue = '';
                draft.jiraFollowupProjectsOffset = 0;
                draft.jiraFollowupProjectsTotal = Number.MAX_SAFE_INTEGER;
                draft.jiraFollowupProjects = [];
                draft.jiraIssueType = '';
                draft.jiraIssueTypes = [];
                draft.jiraFollowUpIssueType = '';
                draft.jiraFollowupIssueTypes = [];
                draft.jiraFollowUpCreateIssueType = '';
                draft.jiraFollowupCreateIssueTypes = [];
                draft.jiraRequiredFields = [];
                return;
            case setJiraProject.getType():
                draft.jiraProjectId = action.payload.jiraProjectId;
                draft.jiraProjectDisplayValue = action.payload.jiraProjectDisplayValue;
                return;

            case setJiraFollowupProject.getType():
                draft.jiraFollowupProjectId = action.payload.jiraFollowupProjectId;
                draft.jiraFollowupProjectDisplayValue = action.payload.jiraFollowupProjectDisplayValue;
                return;

            case setJiraFollowupCreateProject.getType():
                draft.jiraFollowupCreateProjectId = action.payload.jiraFollowupCreateProjectId;
                draft.jiraFollowupCreateProjectDisplayValue = action.payload.jiraFollowupCreateProjectDisplayValue;
                return;

            case setJiraRequiredFields.getType():
                draft.jiraRequiredFields = action.payload.requiredFields;
                return;
            case GET_PAGER_DUTY_SERVICES_RPC:
                if (!action.meta.done) {
                    draft.getPagerDutyStatus = LoadStatus.REQUEST;
                    return;
                }

                if (action.error && action.meta.done) {
                    draft.getPagerDutyStatus = LoadStatus.ERROR;
                    return;
                }

                if (action.meta.done && !action.error) {
                    draft.getPagerDutyStatus = LoadStatus.OK;
                    if (action.payload) {
                        const { pagination, response } = action.payload;
                        draft.pagerDutyList = response.map((service) => ({
                            label: service.name,
                            value: service.meta.id
                        }));
                        draft.pagerDutyPagination = pagination;
                    }
                    return;
                }
                return;
            case getMorePagerDutyServices.request.getType():
                draft.getPagerDutyStatus = LoadStatus.REQUEST;
                return;
            case getMorePagerDutyServices.ok.getType():
                const { pagination, response } = action.payload.response;
                draft.getPagerDutyStatus = LoadStatus.OK;
                draft.pagerDutyPagination = pagination;
                draft.pagerDutyList = state.pagerDutyList.concat(
                    response.map((service) => ({
                        label: service.name,
                        value: service.meta.id
                    }))
                );
                return;
            case getMorePagerDutyServices.error.getType():
                draft.getPagerDutyStatus = LoadStatus.ERROR;
                return;
            case getJiraAssignees.request.getType():
                draft.getJiraAssigneesStatus = LoadStatus.REQUEST;
                return;
            case getJiraAssignees.ok.getType():
                const { users } = action.payload.response;
                draft.getJiraAssigneesStatus = LoadStatus.OK;
                draft.jiraAssignees = users.map((user) => ({ label: user.label, value: user.value.id }));
                return;
            case getJiraAssignees.error.getType():
                draft.getJiraAssigneesStatus = LoadStatus.ERROR;
                return;
            case GET_SERVICENOW_SERVICES_RPC:
                if (!action.meta.done) {
                    draft.getServiceNowStatus = LoadStatus.REQUEST;
                    return;
                }

                if (action.error && action.meta.done) {
                    draft.getServiceNowStatus = LoadStatus.ERROR;
                    return;
                }

                if (action.meta.done && !action.error) {
                    draft.getServiceNowStatus = LoadStatus.OK;
                    if (action.payload) {
                        const { pagination, data } = action.payload;
                        draft.serviceNowList = data.services.map((snowService) => ({
                            field_name: 'service_id',
                            label: snowService.name,
                            value: snowService.id,
                            htmlurl: snowService.url
                        }));
                        draft.serviceNowPagination = pagination;
                    }
                    return;
                }
                return;
            case getMoreServiceNowServices.request.getType():
                draft.getServiceNowStatus = LoadStatus.REQUEST;
                return;
            case getMoreServiceNowServices.ok.getType():
                draft.getServiceNowStatus = LoadStatus.OK;
                draft.serviceNowPagination = action.payload.response.pagination;
                draft.serviceNowList = state.serviceNowList.concat(action.payload.response.response);
                return;
            case getMoreServiceNowServices.error.getType():
                draft.getServiceNowStatus = LoadStatus.ERROR;
                return;
            case GET_OPSGENIE_SERVICES_RPC:
                if (!action.meta.done) {
                    draft.getOpsgenieStatus = LoadStatus.REQUEST;
                    return;
                }

                if (action.error && action.meta.done) {
                    draft.getOpsgenieStatus = LoadStatus.ERROR;
                    return;
                }

                if (action.meta.done && !action.error) {
                    draft.getOpsgenieStatus = LoadStatus.OK;
                    if (action.payload) {
                        const { pagination, response } = action.payload;
                        draft.opsgenieList = response;
                        draft.opsgeniePagination = pagination;
                    }
                    return;
                }
                return;
            case getMoreOpsgenieServices.request.getType():
                draft.getOpsgenieStatus = LoadStatus.REQUEST;
                return;
            case getMoreOpsgenieServices.ok.getType():
                draft.getOpsgenieStatus = LoadStatus.OK;
                draft.opsgeniePagination = action.payload.response.pagination;
                draft.opsgenieList = state.opsgenieList.concat(action.payload.response.response);
                return;
            case getMoreOpsgenieServices.error.getType():
                draft.getOpsgenieStatus = LoadStatus.ERROR;
                return;
        }
    });
}
