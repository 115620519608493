import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';

// Components
import DashboardRoutes from 'src/components/dashboard';
import IdentityManagementRoutes from 'src/components/identity-management';
import IncidentsRoutes from 'src/components/incidents';
import LoadingPageIndicator from 'src/components/ui/loading-page-indicator/loading-page-indicator';
import SRERoutes from 'src/components/slo';
import { useUserPermissions } from 'src/components/auth/user-permissions';
import { usePermissionGuard } from 'src/components/auth/permission-guard';
import ErrorDeletedUser from 'src/components/ui/error-deleted-user/error-deleted-user';

// Actions
import { getOrgs } from 'src/actions/identity-management/identity-management-actions';
import { getUserTimezone, getIntegrationProbes } from 'src/actions/settings/settings-page-actions';

// Constants
import SectionURLs from 'src/constants/urls';

const PostmortemListPage = lazy(() => import('src/components/postmortem/postmortem-list'));
const PostmortemDetailsPage = lazy(() => import('src/components/postmortem/postmortem'));
const SettingsPage = lazy(() => import('src/components/settings'));
const OnboardingPage = lazy(() => import('src/components/onboarding'));
const FollowupActionsPage = lazy(() => import('src/components/followup-actions/followup-actions-page'));
const Runbooks = lazy(() => import('src/components/runbooks'));
const CommsFlowRoutes = lazy(() => import('src/components/comms-flow'));
const ErrorNotFound = lazy(() => import('src/components/ui/error-not-found/error-not-found'));
const SlackAuthFlow = lazy(() => import('src/components/auth/slack-auth-flow'));
const WelcomePage = lazy(() => import('src/components/welcome/welcome-page'));
const DownloadPage = lazy(() => import('src/components/download/download-page'));
const Unauthorized = lazy(() => import('src/components/auth/unauthorized'));

type sectionName =
    | 'incidents'
    | 'retrospectives'
    | 'reliability-insights'
    | 'identity-management'
    | 'settings'
    | 'slo'
    | 'followups'
    | 'runbooks'
    | 'comms-flows'
    | 'onboarding'
    | 'welcome-page'
    | 'slack-auth-flow'
    | 'download';

interface IProps {
    auth: any;
}

interface ISectionPermission {
    resource: string;
    action: string;
}

const sectionPermissions: Record<sectionName, ISectionPermission | undefined> = {
    incidents: {
        resource: 'Incident',
        action: 'Read'
    },
    retrospectives: {
        resource: 'Incident',
        action: 'Read'
    },
    'reliability-insights': {
        resource: 'Dashboard',
        action: 'Read'
    },
    'identity-management': {
        resource: 'Identity',
        action: 'Delete'
    },
    settings: {
        resource: 'Settings',
        action: 'Read'
    },
    slo: {
        resource: 'SLO',
        action: 'Read'
    },
    followups: {
        resource: 'Followup',
        action: 'Update'
    },
    runbooks: {
        resource: 'Runbooks',
        action: 'Read'
    },
    'comms-flows': {
        resource: 'Commsflow',
        action: 'Read'
    },
    onboarding: undefined,
    'welcome-page': undefined,
    'slack-auth-flow': undefined,
    download: undefined
};

const Routes = ({ auth }: IProps) => {
    const [initialized, setInitialized] = useState(false);
    const dispatch = useDispatch();
    const {
        integrationProbes: integrationProbesFlag,
        slosEnabled,
        enableRunbooks,
        enableCommsFlowUi,
        enableSlackOauthFlow,
        msTeamsPoc,
        enableFreeTrialWelcomePage
    } = useSelector((state: RootState) => state.launchDarkly);

    const { isError: isLoadingError, isLoading: isPermissionsLoading, isDeactivatedUser } = useUserPermissions();

    const { isAuthorized: isIncidentReaderAllowed } = usePermissionGuard({
        permissions: [{ component: 'INCIDENT', action: 'READ' }]
    });

    const { isAuthorized: isDashboardReaderAllowed } = usePermissionGuard({
        permissions: [{ component: 'DASHBOARD', action: 'READ' }]
    });

    const { isAuthorized: isRunbooksReaderAllowed } = usePermissionGuard({
        permissions: [{ component: 'RUNBOOKS', action: 'READ' }]
    });

    const { isAuthorized: isIdentityAdminAllowed } = usePermissionGuard({
        permissions: [{ component: 'IDENTITY', action: 'DELETE' }]
    });

    const { isAuthorized: isFollowupAdminAllowed } = usePermissionGuard({
        permissions: [{ component: 'FOLLOWUP', action: 'DELETE' }]
    });

    const { isAuthorized: isSloWriterAllowed } = usePermissionGuard({
        permissions: [{ component: 'SLO', action: 'CREATE' }]
    });

    const { isAuthorized: isSettingsReaderAllowed } = usePermissionGuard({
        permissions: [{ component: 'SETTING', action: 'READ' }]
    });

    const { isAuthorized: isCommsFlowAdminAllowed } = usePermissionGuard({
        permissions: [{ component: 'COMMSFLOW', action: 'DELETE' }]
    });

    useEffect(() => {
        if (integrationProbesFlag) {
            dispatch(getIntegrationProbes());
        }
        if (!initialized) {
            dispatch(getUserTimezone());
            dispatch(getOrgs());
            setInitialized(true);
        }
    }, [initialized, integrationProbesFlag, dispatch]);

    interface IRouteSection {
        name: sectionName;
        path: string;
        exact: boolean;
        enabled: boolean;
        allowed: boolean;
        component: (props?: any) => JSX.Element;
    }
    const routeSections: IRouteSection[] = [
        {
            name: 'incidents',
            path: SectionURLs.INCIDENTS_URL,
            exact: false,
            enabled: true,
            allowed: isIncidentReaderAllowed,
            component: (props: any) => <IncidentsRoutes {...props} />
        },
        {
            name: 'reliability-insights',
            path: SectionURLs.RELIABILITY_INSIGHTS_URL,
            exact: false,
            enabled: true,
            allowed: isDashboardReaderAllowed,
            component: (props: any) => <DashboardRoutes {...props} />
        },
        {
            name: 'retrospectives',
            path: SectionURLs.POSTMORTEM_URL,
            exact: true,
            enabled: true,
            allowed: isIncidentReaderAllowed,
            component: (props: any) => <PostmortemListPage {...props} />
        },
        {
            name: 'retrospectives',
            path: `${SectionURLs.POSTMORTEM_URL}/:id`,
            exact: true,
            enabled: true,
            allowed: isIncidentReaderAllowed,
            component: (props: any) => <PostmortemDetailsPage {...props} />
        },
        {
            name: 'identity-management',
            path: SectionURLs.IDENTITY_MANAGEMENT_URL,
            exact: false,
            enabled: true,
            allowed: isIdentityAdminAllowed,
            component: (props: any) => <IdentityManagementRoutes {...props} />
        },
        {
            name: 'settings',
            path: SectionURLs.SETTINGS_URL,
            exact: false,
            enabled: true,
            allowed: isSettingsReaderAllowed,
            component: (props: any) => <SettingsPage {...props} />
        },
        {
            name: 'followups',
            path: SectionURLs.FOLLOWUP_ACTIONS_URL,
            exact: false,
            enabled: true,
            allowed: isFollowupAdminAllowed,
            component: (props: any) => <FollowupActionsPage {...props} />
        },
        {
            name: 'onboarding',
            path: `${SectionURLs.ONBOARDING_STEP_URL}/:stepId`,
            exact: false,
            enabled: true,
            allowed: true,
            component: (props: any) => <OnboardingPage {...props} />
        },
        {
            name: 'runbooks',
            path: SectionURLs.RUNBOOKS_URL,
            exact: false,
            enabled: enableRunbooks,
            allowed: isRunbooksReaderAllowed,
            component: () => <Runbooks />
        },
        {
            name: 'slo',
            path: SectionURLs.SLO_MANAGE_URL,
            exact: false,
            enabled: slosEnabled,
            allowed: isSloWriterAllowed,
            component: (props: any) => <SRERoutes {...props} />
        },
        {
            name: 'comms-flows',
            path: SectionURLs.COMMS_FLOW_URL,
            exact: false,
            enabled: enableCommsFlowUi,
            allowed: isCommsFlowAdminAllowed,
            component: () => <CommsFlowRoutes />
        },
        {
            name: 'slack-auth-flow',
            path: SectionURLs.SLACK_AUTH_FLOW_URL,
            exact: false,
            enabled: enableSlackOauthFlow,
            allowed: true,
            component: () => <SlackAuthFlow />
        },
        {
            name: 'welcome-page',
            path: SectionURLs.WELCOME_PAGE_URL,
            exact: false,
            enabled: !msTeamsPoc && enableFreeTrialWelcomePage,
            allowed: true,
            component: () => <WelcomePage />
        },
        {
            name: 'download',
            path: SectionURLs.DOWNLOAD_URL,
            exact: false,
            enabled: true,
            allowed: true,
            component: () => <DownloadPage />
        }
    ];

    const renderError = () => {
        if (isDeactivatedUser) {
            return (
                <Route render={(props: RouteComponentProps) => <ErrorDeletedUser {...props} logout={auth.logout} />} />
            );
        }
        if (isLoadingError) {
            return <Route render={(props: RouteComponentProps) => <ErrorNotFound {...props} />} />;
        }
    };
    if (!auth.isAuthenticated()) {
        auth.login();
        return <Redirect to="/" push />;
    }

    return (
        <Suspense fallback={<LoadingPageIndicator />}>
            <Switch>
                <Redirect exact from={SectionURLs.POSTMORTEM_URL_OLD} to={SectionURLs.POSTMORTEM_URL} />
                <Redirect
                    exact
                    from={`${SectionURLs.POSTMORTEM_URL_OLD}/:id`}
                    to={`${SectionURLs.POSTMORTEM_URL}/:id`}
                />
                {!(isPermissionsLoading || isDeactivatedUser) &&
                    routeSections
                        .filter((section) => section.enabled)
                        .map((section) => (
                            <Route
                                key={section.path}
                                path={section.path}
                                exact={section.exact}
                                render={(props: RouteComponentProps) =>
                                    section.allowed ? (
                                        section.component(props)
                                    ) : (
                                        <Unauthorized
                                            {...props}
                                            resource={sectionPermissions[section.name]?.resource}
                                            action={sectionPermissions[section.name]?.action}
                                        />
                                    )
                                }
                            />
                        ))}
                {renderError()}
            </Switch>
        </Suspense>
    );
};

export default Routes;
