import { init, setDeviceId, track, setGroup } from '@amplitude/analytics-browser';
import Auth from 'src/auth/auth';
import { hashToSha256 } from 'src/utils/common-utils';

export const trackEvent = (eventName: string, eventProperties?: Record<string, any>) => {
    try {
        if (!eventProperties) {
            eventProperties = {};
        }
        const subdomain = getSubdomain(window.location.hostname);
        eventProperties['account_id'] = subdomain;
        eventProperties['service_id'] = 'showcase';
        track(eventName, eventProperties);
    } catch (ex) {
        console.error('amplitude error', ex);
    }
};

const getEmail = (): string => {
    const profile = Auth.getLocalStorageProfile();
    if (!profile) {
        return '';
    }
    return profile.email;
};

export const getSubdomain = (hostname: string): string => {
    const subdomainLimit = hostname.indexOf('.', 0);
    if (subdomainLimit < 0) {
        return hostname;
    }
    return hostname.substring(0, subdomainLimit).toLowerCase();
};

const initialize = async (): Promise<void> => {
    init('ff1a9122f6155c6517172205b460c12c');

    const hostname = window.location.hostname;
    const email = getEmail();
    const deviceId = await hashToSha256(`${hostname}${email}`.toLowerCase(), '-)4,1)%Q');
    setDeviceId(deviceId.toLowerCase());

    const subdomain = getSubdomain(hostname);
    setGroup('account_id', subdomain);
};

initialize();
