import $api from 'src/utils/api-interface';
import { createAction } from 'redux-act';
import { createActionAsync } from 'redux-act-async';
import { Dispatch } from 'redux';

interface IDispatchObject {
    type: string;
    payload?: any;
    error?: any;
    meta: any;
}

export const GET_MS_TEAMS_GROUPS_RPC = 'GET_MS_TEAMS_GROUPS_RPC';
export const getMsTeamsGroups = (query = '') => {
    return (dispatch: Dispatch) => {
        const dispatchObject: IDispatchObject = {
            type: GET_MS_TEAMS_GROUPS_RPC,
            payload: {},
            error: {},
            meta: {
                id: GET_MS_TEAMS_GROUPS_RPC,
                done: false
            }
        };

        if (!dispatch(dispatchObject)) {
            return;
        }

        $api.get({
            url: '/ms-teams-groups',
            params: {
                query
            }
        })
            .then((res: any) => {
                dispatchObject.error = undefined;
                dispatchObject.payload = res.data.groups;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            })
            .catch((error: any) => {
                dispatchObject.payload = undefined;
                dispatchObject.error = error;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            });
    };
};

export const GET_MS_TEAMS_CHANNELS_RPC = 'GET_MS_TEAMS_CHANNELS_RPC';
export const getMsTeamsChannels = (groupId, query = '') => {
    return (dispatch: Dispatch) => {
        const dispatchObject: IDispatchObject = {
            type: GET_MS_TEAMS_CHANNELS_RPC,
            payload: {},
            error: {},
            meta: {
                id: GET_MS_TEAMS_CHANNELS_RPC,
                done: false
            }
        };

        if (!dispatch(dispatchObject)) {
            return;
        }

        $api.get({
            url: `/ms-teams-groups/${groupId}/channels`,
            params: {
                query
            }
        })
            .then((res: any) => {
                dispatchObject.error = undefined;
                dispatchObject.payload = res.data.channels;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            })
            .catch((error: any) => {
                dispatchObject.payload = undefined;
                dispatchObject.error = error;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            });
    };
};

export const GET_MS_TEAMS_USERS_RPC = 'GET_MS_TEAMS_USERS_RPC';
export const getMsTeamsUsers = (query = '') => {
    return (dispatch: Dispatch) => {
        const dispatchObject: IDispatchObject = {
            type: GET_MS_TEAMS_USERS_RPC,
            payload: {},
            error: {},
            meta: {
                id: GET_MS_TEAMS_USERS_RPC,
                done: false
            }
        };

        if (!dispatch(dispatchObject)) {
            return;
        }

        $api.get({
            url: '/ms-teams-users',
            params: {
                query
            }
        })
            .then((res: any) => {
                dispatchObject.error = undefined;
                const users = res.data.users.map((user) => {
                    return { id: user.profile._id, displayName: user.profile.display_name };
                });
                dispatchObject.payload = users;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            })
            .catch((error: any) => {
                dispatchObject.payload = undefined;
                dispatchObject.error = error;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            });
    };
};

function getProjects(type: string, limit = 20, offset = 0, query = '', dispatch: Dispatch) {
    const dispatchObject: IDispatchObject = {
        type: type,
        payload: {},
        error: {},
        meta: {
            id: type,
            done: false
        }
    };

    if (!dispatch(dispatchObject)) {
        return;
    }

    $api.get({
        url: '/settings-metadata/get_options',
        params: {
            valueOptionsSource: 'rpc:atlas.get_projects_options',
            limit,
            offset,
            query
        }
    })
        .then((res: any) => {
            dispatchObject.error = undefined;
            dispatchObject.payload = res.data.settingsOptions;
            dispatchObject.meta.done = true;
            return dispatch(dispatchObject);
        })
        .catch((error: any) => {
            dispatchObject.payload = undefined;
            dispatchObject.error = error;
            dispatchObject.meta.done = true;
            return dispatch(dispatchObject);
        });
}

export const GET_JIRA_PROJECTS_RPC = 'GET_JIRA_PROJECTS_RPC';
export const getJiraProjects = (limit = 20, offset = 0, query = '') => {
    return (dispatch: Dispatch) => {
        return getProjects(GET_JIRA_PROJECTS_RPC, limit, offset, query, dispatch);
    };
};

export const GET_JIRA_FOLLOWUP_PROJECTS_RPC = 'GET_JIRA_FOLLOWUP_PROJECTS_RPC';
export const getJiraFollowupProjects = (limit = 20, offset = 0, query = '') => {
    return (dispatch: Dispatch) => {
        return getProjects(GET_JIRA_FOLLOWUP_PROJECTS_RPC, limit, offset, query, dispatch);
    };
};

export const GET_JIRA_FOLLOWUP_CREATE_PROJECTS_RPC = 'GET_JIRA_FOLLOWUP_CREATE_PROJECTS_RPC';
export const getJiraFollowupCreateProjects = (limit = 20, offset = 0, query = '') => {
    return (dispatch: Dispatch) => {
        return getProjects(GET_JIRA_FOLLOWUP_CREATE_PROJECTS_RPC, limit, offset, query, dispatch);
    };
};

function getIssueTypes(type: string, projectId: string, dispatch: Dispatch) {
    const dispatchObject: IDispatchObject = {
        type: type,
        payload: {},
        error: {},
        meta: {
            id: type,
            done: true
        }
    };

    if (!dispatch(dispatchObject)) {
        return;
    }

    $api.get({
        url: '/settings-metadata/get_options',
        params: {
            valueOptionsSource: 'rpc:atlas.get_types_options',
            name: 'org.integration.jira-incident-ticket-project',
            currentValue: projectId
        }
    })
        .then((res: any) => {
            dispatchObject.error = undefined;
            dispatchObject.payload = res.data.settingsOptions;
            dispatchObject.meta.done = true;
            return dispatch(dispatchObject);
        })
        .catch((error: any) => {
            dispatchObject.payload = undefined;
            dispatchObject.error = error;
            dispatchObject.meta.done = true;
            return dispatch(dispatchObject);
        });
}

export const GET_JIRA_ISSUE_TYPES_RPC = 'GET_JIRA_ISSUE_TYPES_RPC';
export const getJiraIssueTypes = (projectId: string) => {
    return (dispatch: Dispatch) => {
        return getIssueTypes(GET_JIRA_ISSUE_TYPES_RPC, projectId, dispatch);
    };
};

export const GET_JIRA_FOLLOWUP_ISSUE_TYPES_RPC = 'GET_JIRA_FOLLOWUP_ISSUE_TYPES_RPC';
export const getJiraFollowupIssueTypes = (projectId: string) => {
    return (dispatch: Dispatch) => {
        return getIssueTypes(GET_JIRA_FOLLOWUP_ISSUE_TYPES_RPC, projectId, dispatch);
    };
};

export const GET_JIRA_FOLLOWUP_CREATE_ISSUE_TYPES_RPC = 'GET_JIRA_FOLLOWUP_CREATE_ISSUE_TYPES_RPC';
export const getJiraFollowupCreateIssueTypes = (projectId: string) => {
    return (dispatch: Dispatch) => {
        return getIssueTypes(GET_JIRA_FOLLOWUP_CREATE_ISSUE_TYPES_RPC, projectId, dispatch);
    };
};

export const GET_JIRA_REQUIRED_FIELDS = 'GET_JIRA_REQUIRED_FIELDS';
export const getJiraRequiredFields = (
    projectId: string,
    issueType: string,
    type: string,
    rpc = 'rpc:atlas.get_required_fields_ui_metadata_for_settings_page'
) => {
    return (dispatch: Dispatch) => {
        const dispatchObject: IDispatchObject = {
            type: GET_JIRA_REQUIRED_FIELDS,
            payload: {},
            error: {},
            meta: {
                id: GET_JIRA_REQUIRED_FIELDS,
                done: true
            }
        };

        if (!dispatch(dispatchObject)) {
            return;
        }

        $api.get({
            url: '/settings-metadata/get_options',
            params: {
                valueOptionsSource: rpc,
                project: projectId,
                issue_type: issueType
            }
        })
            .then((res: any) => {
                const { settings, requiredFields } = res.data.settingsOptions;
                dispatchObject.error = undefined;
                dispatchObject.payload = { fields: settings ?? requiredFields ?? [], type };
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            })
            .catch((error: any) => {
                dispatchObject.payload = undefined;
                dispatchObject.error = error;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            });
    };
};

export const getJiraAssignees = createActionAsync('GET_JIRA_ASSIGNEES', (params: any) =>
    $api
        .get({ url: '/users/jira', params })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            throw error;
        })
);

// Set Microsoft Teams Fields
export const SET_MS_TEAMS_GROUP = 'SET_MS_TEAMS_GROUP';
export const setMsTeamsGroup = createAction(SET_MS_TEAMS_GROUP, (msTeamsGroupsId, msTeamsGroupsDisplayName) => ({
    msTeamsGroupsId,
    msTeamsGroupsDisplayName
}));

export const CLEAR_MS_TEAMS_GROUP_REQUIRED_FIELDS = 'CLEAR_MS_TEAMS_GROUP_REQUIRED_FIELDS';
export const clearMsTeamsGroupsRequiredFields = createAction(CLEAR_MS_TEAMS_GROUP_REQUIRED_FIELDS);

export const SET_MS_TEAMS_CHANNEL = 'SET_MS_TEAMS_CHANNEL';
export const setMsTeamsChannel = createAction('SET_MS_TEAMS_CHANNEL', (channels) => channels);
export const CLEAR_MS_TEAMS_CHANNEL_REQUIRED_FIELDS = 'CLEAR_MS_TEAMS_CHANNEL_REQUIRED_FIELDS';
export const clearMsTeamsChannelsRequiredFields = createAction(CLEAR_MS_TEAMS_CHANNEL_REQUIRED_FIELDS);

export const SET_MS_TEAMS_USER = 'SET_MS_TEAMS_USER';
export const setMsTeamsUser = createAction('SET_MS_TEAMS_USER', (users) => users);
export const CLEAR_MS_TEAMS_USER_REQUIRED_FIELDS = 'CLEAR_MS_TEAMS_USER_REQUIRED_FIELDS';
export const clearMsTeamsUsersRequiredFields = createAction(CLEAR_MS_TEAMS_USER_REQUIRED_FIELDS);

// Set Jira State Fields
export const setJiraProject = createAction('SET_JIRA_PROJECT', (jiraProjectId, jiraProjectDisplayValue) => ({
    jiraProjectId,
    jiraProjectDisplayValue
}));
export const setJiraFollowupProject = createAction(
    'SET_JIRA_FOLLOWUP_PROJECT',
    (jiraFollowupProjectId, jiraFollowupProjectDisplayValue) => ({
        jiraFollowupProjectId,
        jiraFollowupProjectDisplayValue
    })
);
export const setJiraFollowupCreateProject = createAction(
    'SET_JIRA_FOLLOWUP_CREATE_PROJECT',
    (jiraFollowupCreateProjectId, jiraFollowupCreateProjectDisplayValue) => ({
        jiraFollowupCreateProjectId,
        jiraFollowupCreateProjectDisplayValue
    })
);
export const setJiraIssueType = createAction('SET_JIRA_ISSUE_TYPE', (issueType) => ({ issueType }));
export const setJiraIssueTypes = createAction('SET_JIRA_ISSUE_TYPES', (issueTypes) => ({ issueTypes }));
export const setJiraFollowUpIssueType = createAction('SET_JIRA_FOLLOW_UP_ISSUE_TYPE', (followUpIssueType) => ({
    followUpIssueType
}));
export const setJiraFollowUpIssueTypes = createAction('SET_JIRA_FOLLOW_UP_ISSUE_TYPES', (followUpIssueTypes) => ({
    followUpIssueTypes
}));
export const setJiraFollowUpCreateIssueType = createAction(
    'SET_JIRA_FOLLOW_UP_CREATE_ISSUE_TYPE',
    (followUpCreateIssueType) => ({
        followUpCreateIssueType
    })
);
export const setJiraFollowUpCreateIssueTypes = createAction(
    'SET_JIRA_FOLLOW_UP_CREATE_ISSUE_TYPES',
    (followUpCreateIssueTypes) => ({
        followUpCreateIssueTypes
    })
);
export const setJiraRequiredFields = createAction('SET_JIRA_REQUIRED_FIELDS', (requiredFields) => ({ requiredFields }));

// Clear Jira State Fields
export const clearJiraProject = createAction('CLEAR_JIRA_PROJECT');
export const clearJiraProjects = createAction('CLEAR_JIRA_PROJECTS');
export const clearJiraIssueType = createAction('CLEAR_JIRA_ISSUE_TYPE');
export const clearJiraIssueTypes = createAction('CLEAR_JIRA_ISSUE_TYPES');
export const clearAllJiraProperties = createAction('CLEAR_ALL_JIRA_PROPERTIES');
export const clearJiraRequiredFields = createAction(
    'CLEAR_JIRA_REQUIRED_FIELDS',
    (type: 'incident' | 'followup' | 'followup_create') => ({
        type
    })
);
export const clearJiraFollowUpIssueType = createAction('CLEAR_JIRA_FOLLOW_UP_ISSUE_TYPE');
export const clearJiraFollowUpCreateIssueType = createAction('CLEAR_JIRA_FOLLOW_UP_CREATE_ISSUE_TYPE');
// PagerDuty
export const GET_PAGER_DUTY_SERVICES_RPC = 'GET_PAGER_DUTY_SERVICES_RPC';
export const getPagerDutyServices = (limit = 100, offset = 0) => {
    return (dispatch: Dispatch) => {
        const dispatchObject: IDispatchObject = {
            type: GET_PAGER_DUTY_SERVICES_RPC,
            payload: {},
            error: {},
            meta: {
                id: GET_PAGER_DUTY_SERVICES_RPC,
                done: false
            }
        };

        if (!dispatch(dispatchObject)) {
            return;
        }

        $api.get({
            url: '/pagerduty/services',
            params: {
                limit,
                offset
            }
        })
            .then((res: any) => {
                dispatchObject.error = undefined;
                dispatchObject.payload = res.data;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            })
            .catch((error: any) => {
                dispatchObject.payload = undefined;
                dispatchObject.error = error;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            });
    };
};

export const getMorePagerDutyServices = createActionAsync('GET_MORE_PAGER_DUTY_SERVICES', (limit, offset) =>
    $api
        .get({
            url: '/pagerduty/services',
            params: {
                limit,
                offset
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            throw error;
        })
);

// ServiceNow
export const GET_SERVICENOW_SERVICES_RPC = 'GET_SERVICENOW_SERVICES_RPC';
export const getServiceNowServices = (limit = 100, offset = 0) => {
    return (dispatch: Dispatch) => {
        const dispatchObject: IDispatchObject = {
            type: GET_SERVICENOW_SERVICES_RPC,
            payload: {},
            error: {},
            meta: {
                id: GET_SERVICENOW_SERVICES_RPC,
                done: false
            }
        };

        if (!dispatch(dispatchObject)) {
            return;
        }

        $api.get({
            url: '/servicenow/services',
            params: {
                limit,
                offset
            }
        })
            .then((res: any) => {
                dispatchObject.error = undefined;
                dispatchObject.payload = res.data;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            })
            .catch((error: any) => {
                dispatchObject.payload = undefined;
                dispatchObject.error = error;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            });
    };
};

export const getMoreServiceNowServices = createActionAsync('GET_MORE_SERVICENOW_SERVICES', (limit, offset) =>
    $api
        .get({
            url: '/servicenow/services',
            params: {
                limit,
                offset
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            throw error;
        })
);

// Opsgenie
export const GET_OPSGENIE_SERVICES_RPC = 'GET_OPSGENIE_SERVICES_RPC';
export const getOpsgenieServices = (limit = 100, offset = 0) => {
    return (dispatch: Dispatch) => {
        const dispatchObject: IDispatchObject = {
            type: GET_OPSGENIE_SERVICES_RPC,
            payload: {},
            error: {},
            meta: {
                id: GET_OPSGENIE_SERVICES_RPC,
                done: false
            }
        };

        if (!dispatch(dispatchObject)) {
            return;
        }
        $api.get({
            url: '/opsgenie',
            params: {
                limit,
                offset
            }
        })
            .then((res: any) => {
                dispatchObject.error = undefined;
                dispatchObject.payload = res.data;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            })
            .catch((error: any) => {
                dispatchObject.payload = undefined;
                dispatchObject.error = error;
                dispatchObject.meta.done = true;
                return dispatch(dispatchObject);
            });
    };
};

export const getMoreOpsgenieServices = createActionAsync('GET_MORE_OPSGENIE_SERVICES', (limit, offset) =>
    $api
        .get({
            url: '/opsgenie',
            params: {
                limit,
                offset
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            throw error;
        })
);
