// @ts-nocheck
import $http from 'axios';
import { merge, isEmpty } from 'lodash';
import axiosRetry from 'axios-retry';

const envConfig = ShowcaseConfig;
import Auth from 'src/auth/auth';
import { configureScope, captureMessage } from '@sentry/react';

const defaultRetryCondition = (error) => {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at')!);
    const timetoExpiration = expiresAt - Date.now();
    return error.response.status >= 400 || timetoExpiration <= 0;
};

const defaultStatusCodesToPreventAlert = [403];

class Api {
    constructor(config) {
        this.$http = $http.create({
            baseURL: config.url.includes('://') ? config.url : `${location.origin}${config.url}`,
            timeout: config.timeout
        });

        // Add an auth token to all requests.
        this.$http.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${Auth.getAccessToken()}`;

            return config;
        });

        axiosRetry(this.$http, {
            retries: 0,
            retryCondition: defaultRetryCondition,
            retryDelay: (retryCount: number) => {
                return retryCount * 500;
            }
        });
    }

    resolve = (req) => {
        if (req.status > 499) {
            return Promise.reject(req);
        }
        return Promise.resolve(req);
    };

    catch = (req, config: IApi.RequestConfig = { preventAlertingOnStatusCodes: [] }) => {
        const profile = Auth.getLocalStorageProfile();
        const { response } = req;
        const statusCodesToPreventAlerting = [
            ...defaultStatusCodesToPreventAlert,
            ...(config.preventAlertingOnStatusCodes || [])
        ];
        if (import.meta.env.PROD && response.status > 401 && !statusCodesToPreventAlerting.includes(response.status)) {
            configureScope((scope) => {
                if (profile) {
                    scope.setUser({
                        name: profile.name,
                        email: profile.email
                    });
                }
                scope.setLevel(response.status > 499 ? 'fatal' : 'error');
                scope.setTag('hostname', window.location.href);
                if (window?.FS?.getCurrentSessionURL) {
                    scope.setExtra('fullstory_session', window.FS.getCurrentSessionURL());
                }
                scope.setExtra('response_body', JSON.stringify(req.response.data));
                captureMessage(response.status.toString(), scope);
            });
        }
        return Promise.reject(req);
    };

    doRequest = (method, args, config: IApi.RequestConfig = { retries: 0 }) => {
        return this.$http
            .request(
                merge(
                    {
                        method: method,
                        'axios-retry': {
                            retries: config.retries,
                            retryCondition: config.retryCondition || defaultRetryCondition
                        }
                    },
                    args
                )
            )
            .then((res) => this.resolve(res))
            .catch((err) => this.catch(err, config));
    };

    get = (args, config: IApi.RequestConfig) => {
        return this.doRequest('GET', args, config);
    };

    put = (args, config: IApi.RequestConfig) => {
        if (!Object.hasOwnProperty.call(args, 'data')) {
            console.warn('PUT must contain data object!');
            args.data = {};
        } else if (isEmpty(args.data)) {
            console.warn('PUT data must not be empty, nothing PUT!');
        }

        return this.doRequest('PUT', args, config);
    };

    post = (args, config: IApi.RequestConfig) => {
        if (!Object.hasOwnProperty.call(args, 'data')) {
            console.warn('POST must contain data object!');
            args.data = {};
        } else if (isEmpty(args.data)) {
            console.warn("POST data must not be empty, nothing POST'ed!");
        }

        return this.doRequest('POST', args, config);
    };

    head = (args, config: IApi.RequestConfig) => {
        return this.doRequest('HEAD', args, config);
    };

    patch = (args, config: IApi.RequestConfig) => {
        if (!Object.hasOwnProperty.call(args, 'data')) {
            console.warn('PATCH must contain data object!');
            args.data = {};
        } else if (isEmpty(args.data)) {
            console.warn("PATCH data must not be empty, nothing PATCH'ed!");
        }
        return this.doRequest('PATCH', args, config);
    };

    delete = (args, config: IApi.RequestConfig) => {
        return this.doRequest('DELETE', args, config);
    };

    options = (args, config: IApi.RequestConfig) => {
        return this.doRequest('OPTIONS', args, config);
    };
}

const apiV1 = new Api(envConfig.api);

export default {
    get: (...args) => apiV1.get.apply(apiV1, args),
    put: (...args) => apiV1.put.apply(apiV1, args),
    post: (...args) => apiV1.post.apply(apiV1, args),
    head: (...args) => apiV1.head.apply(apiV1, args),
    patch: (...args) => apiV1.patch.apply(apiV1, args),
    delete: (...args) => apiV1.delete.apply(apiV1, args),
    options: (...args) => apiV1.options.apply(apiV1, args),
    request: (...args) => apiV1.request.apply(apiV1, args)
};

export const ApiClass = Api;
