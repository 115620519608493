import { createAction } from 'redux-act';
import { $api_exporter_v2 } from 'src/utils/apis/v2';
import { createActionAsync } from 'redux-act-async';
import $api from 'src/utils/api-interface';
import { PAGE_SIZE } from 'src/constants/common';
import { STATUS_CODES } from 'src/constants/status';

export const setPostmortemValue = createAction('SET_POSTMORTEM_VALUE');
export const setPostmortemValues = createAction('SET_POSTMORTEM_VALUES');
export const sseUpdatePostmortem = createAction<Postmortem.IDetail>('SSE_UPDATE_POSTMORTEM');
export const setIsCQModal = createAction('SET_IS_CQ_MODAL', (isCQModal) => isCQModal);

export const getFirstPageOfPostmortems = createActionAsync(
    'GET_LIST_OF_POSTMORTEMS',
    (pageNum = 0, filtersQuery = '') =>
        $api
            .get({
                url: `/postmortems?limit=${PAGE_SIZE}&offset=${PAGE_SIZE * pageNum}${
                    filtersQuery ? `&${filtersQuery}` : ''
                }`
            })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

export const getMorePostmortems = createActionAsync('GET_MORE_POSTMORTEMS', (pageNum = 0, filtersQuery = '') =>
    $api
        .get({
            url: `/postmortems?limit=${PAGE_SIZE}&offset=${PAGE_SIZE * pageNum}${
                filtersQuery ? `&${filtersQuery}` : ''
            }`
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getComputedPostmortem = createActionAsync('GET_COMPUTED_POSTMORTEM', (id) => {
    const incidentDetailsPromise = $api.get({
        url: `/incidents/${id}`
    });
    const computedPostmortemPromise = $api.get({
        url: `/incidents/${id}/postmortem`
    });

    return Promise.all([incidentDetailsPromise, computedPostmortemPromise])
        .then(([incident, postmortem]) => ({
            incidentData: incident.data,
            postmortemData: postmortem.data
        }))
        .catch((error) => {
            throw error;
        });
});

export const getServiceNowImportFields = createActionAsync('GET_SERVICENOW_IMPORT_FIELDS', (snowIncId) =>
    $api
        .get({ url: `/integration/servicenow/fields/${snowIncId}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const createPostmortem = createActionAsync('CREATE_POSTMORTEM', (id, data) =>
    $api
        .put({ url: `/incidents/${id}/postmortem`, data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const createIncidentlessPostmortem = createActionAsync('CREATE_INCIDENTLESS_POSTMORTEM', (data) =>
    $api
        .post({
            url: '/postmortems',
            data,
            headers: { 'Content-Type': 'application/json;charset=UTF-8' }
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updatePostmortem = createActionAsync('UPDATE_POSTMORTEM', (id, data) =>
    $api
        .put({ url: `/incidents/${id}/postmortem`, data })
        .then((res) => res.data)
        .catch((error) => {
            if (error.response && error.response.status !== STATUS_CODES.UNPROCESSABLE_ENTITY) {
                throw error;
            }
        })
);

export const addTagToPostmortem = createActionAsync('ADD_TAG_TO_POSTMORTEM', (id, data) =>
    $api
        .put({ url: `/incidents/${id}/postmortem/tag`, data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const removeTagFromPostmortem = createActionAsync('REMOVE_TAG_FROM_POSTMORTEM', (id, data) =>
    $api
        .delete({ url: `/incidents/${id}/postmortem/tag`, data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getPostmortem = createActionAsync('GET_POSTMORTEM', (id) =>
    $api
        .get({ url: `/incidents/${id}/postmortem` })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            throw error;
        })
);

export const getAvailableStates = createActionAsync('GET_AVAILABLE_STATES', (postmortemId = null) => {
    return $api
        .get({
            url:
                postmortemId && typeof postmortemId !== 'function'
                    ? `/postmortem-states?incident_id=${postmortemId}`
                    : '/postmortem-states'
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
});

export const getCollaborativeEditor = createActionAsync('GET_COLLABORATIVE_EDITOR', (id, session_expires_at) =>
    $api
        .get({ url: `/incidents/${id}/collaborative_editor?session_expires_at=${session_expires_at}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getCustomTabSetting = createActionAsync('GET_CUSTOM_TAB_SETTING', () =>
    $api
        .get({ url: '/settings/custom-tab' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getExportLinks = createActionAsync('GET_EXPORT_LINKS', (id: string) =>
    $api_exporter_v2
        .get({ url: `/${id}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);
