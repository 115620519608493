import React from 'react';
const config = ShowcaseConfig;
import { logSentryError } from 'src/utils/sentry';
import Auth from 'src/auth/auth';

import {
    ApolloProvider,
    ApolloClient,
    HttpLink,
    ApolloLink,
    Observable,
    split,
    Operation,
    FetchResult
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { print } from 'graphql';
import { createClient, ClientOptions, Client } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

import ApolloMemorySingleton from 'src/utils/apollo-memory-cache';
import store from 'src/store';
import { enqueueSnackbar } from 'src/actions/notification/notification-actions';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

class WebSocketLink extends ApolloLink {
    private client: Client;

    constructor(options: ClientOptions) {
        super();
        this.client = createClient(options);
    }

    public request(operation: Operation): Observable<FetchResult> {
        return new Observable((sink) => {
            return this.client.subscribe<FetchResult>(
                { ...operation, query: print(operation.query) },
                {
                    next: sink.next.bind(sink),
                    complete: sink.complete.bind(sink),
                    error: (err) => {
                        if (err instanceof Error) {
                            sink.error(err);
                        } else if (err instanceof CloseEvent) {
                            if (err.wasClean && err.reason.includes('jwt')) {
                                console.error(err.reason ?? '');
                                this.client.dispose();
                            } else {
                                sink.error(
                                    new Error(
                                        `Socket closed with event ${err.code}${err.reason}`
                                            ? `: ${err.reason}` // reason will be available on clean closes
                                            : ''
                                    )
                                );
                            }
                        } else {
                            sink.error.bind(sink);
                        }
                    }
                }
            );
        });
    }
}

const getAuthToken = () => {
    try {
        return Auth.getAccessToken();
    } catch (_e) {
        // not logged in
    }
    return '';
};

export const BlamelessApolloProvider: React.FC = ({ children }) => {
    const authProfile = useSelector((state: RootState) => state.auth.authProfile);

    const request = (operation) => {
        const authToken = getAuthToken();
        if (authToken) {
            operation.setContext({
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });
        }
    };

    const requestLink = new ApolloLink((operation, forward) => {
        const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
        return new Observable((observer) => {
            let handle;
            Promise.resolve(operation)
                .then((oper) => request(oper))
                .then(() => {
                    handle = forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer)
                    });
                })
                .catch(observer.error.bind(observer));

            return () => {
                if (handle) handle.unsubscribe();
            };
        });
    });

    const wsLink = new WebSocketLink({
        url:
            config.env === 'prod'
                ? `wss://${window.location.host}${config.graphqlWS.url}`
                : `ws://${window.location.host}${config.graphqlWS.url}`,
        connectionParams: () => {
            const authToken = getAuthToken();
            return {
                Authorization: `Bearer ${authToken}`
            };
        }
    });

    const httpLink = new HttpLink({
        uri: config.graphql.url
    });

    const link = split(
        // split based on operation type
        ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        wsLink,
        httpLink
    );

    const client = new ApolloClient({
        link: ApolloLink.from([
            onError(({ graphQLErrors, networkError }) => {
                if (graphQLErrors)
                    graphQLErrors.map(({ message, locations, path }) => {
                        const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                        console.error(errorMessage);
                        store.dispatch(
                            enqueueSnackbar({
                                message: errorMessage,
                                options: {
                                    variant: 'error'
                                }
                            })
                        );
                        logSentryError(new Error(errorMessage), authProfile);
                    });

                if (networkError) {
                    const errorMessage = `[Network error]: ${networkError}`;
                    console.error(errorMessage);
                    store.dispatch(
                        enqueueSnackbar({
                            message: errorMessage,
                            options: {
                                variant: 'error'
                            }
                        })
                    );
                }
            }),
            requestLink,
            link
        ]),
        cache: ApolloMemorySingleton
    });

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default BlamelessApolloProvider;
